/* eslint-disable react/prop-types */

import { RANGES } from '../share/Constant';

/* eslint-disable react/react-in-jsx-scope */
export default function ActionSheet(props) {
  const { show, setActionSheetShow } = props;

  function selectDateRange(event) {
    props.setDateRange(event);
    setActionSheetShow(false);
  }
  return (
    <div
      className={`offcanvas offcanvas-bottom rounded-m offcanvas-detached bg-theme ${
        show ? 'show' : ''
      }`}
      id="menu-share-list"
      aria-modal="true"
      role="dialog">
      <div className="content">
        <div className="d-flex pb-2">
          <div className="align-self-center">
            <h5 className="mb-n1 font-12 color-highlight font-700 text-uppercase pt-1">
              Just tap and
            </h5>
            <h1 className="font-700">Select Filter</h1>
          </div>
          <div className="align-self-center ms-auto">
            <a
              onClick={() => setActionSheetShow(false)}
              data-bs-dismiss="offcanvas"
              className="icon icon-m">
              <i className="bi bi-x-circle-fill color-red-dark font-18 me-n4"></i>
            </a>
          </div>
        </div>
        <div className="list-group list-custom list-group-m rounded-xs">
          {RANGES.map((range) => (
            <a
              key={range.value}
              onClick={() => selectDateRange(range.value)}
              className="list-group-item">
              <div>{range.label}</div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
