/* eslint-disable react/react-in-jsx-scope */
function List() {
  return (
    <div className="card card-style">
      <div className="content">
        <h4>Simple Icon With Double Lines</h4>
        <p className="mb-3 font-13">Use lists to call, mail, or open action sheets.</p>
        <div className="list-group list-custom list-group-m rounded-m">
          <a href="tel:+1234567890" className="list-group-item">
            <img src="images/pictures/2s.jpg" width="40" className="me-2 rounded-xs" />
            <div className="ps-1">
              <strong className="font-600">Call John Doe</strong>
              <span>Front End Developer</span>
            </div>
            <i className="bi bi-chevron-right"></i>
          </a>
          <a href="mailto:name@domain.com" className="list-group-item">
            <img src="images/pictures/7s.jpg" width="40" className="me-2 rounded-xs" />
            <div className="ps-1">
              <strong className="font-600">Mail Karla Black</strong>
              <span>Marketing Manager</span>
            </div>
            <i className="bi bi-chevron-right"></i>
          </a>
          <a
            href="#"
            data-bs-toggle="offcanvas"
            data-bs-target="#menu-user"
            className="list-group-item">
            <img src="images/pictures/23s.jpg" width="40" className="me-2 rounded-xs" />
            <div className="ps-1">
              <strong className="font-600">View Gamer Girl</strong>
              <span>Procrastination Developer</span>
            </div>
            <i className="bi bi-chevron-right"></i>
          </a>
        </div>
      </div>
    </div>
  );
}

export default List;
