/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import './App.css';
import ErrorBoundary from './components/ErrorBoundary';
import { BrowserRouter as Router, Navigate, Route } from 'react-router-dom';
import RoutePage from './pages/RoutePage';
import Layout from './components/Layout';
import { CommonProvider } from './commonContext';
import SuccessToaster from './share/SuccessToaster';
import { currentDate } from './share/Utils';
import ActionSheet from './UI/ActionSheet';

function App() {
  const [theme, setTheme] = useState(true);

  const [contextData, setContextData] = useState({
    title: 'pocketKhata',
    page: 'pocketKhata',
    toast: {
      type: '',
      show: false,
      message: ''
    },
    selectedDate: `${currentDate}`,
    token: '',
    billUpdated: false,
    paymentUpdated: false,
    customerMankindFlag: false
  });

  return (
    <ErrorBoundary>
      <CommonProvider value={{ contextData, setContextData }}>
        <div className={`${theme ? 'theme-light' : 'theme-dark'}`}>
          <Router>
            {/* <div id="page"> */}
            <Layout setTheme={setTheme} />

            {/* <div className="page-content header-clear-medium"> */}
            <RoutePage></RoutePage>
            {/* </div> */}
            {contextData.toast.show && <SuccessToaster />}
          </Router>
        </div>
      </CommonProvider>
    </ErrorBoundary>
  );
}

export default App;
