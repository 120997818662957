/* eslint-disable react/react-in-jsx-scope */
export default function Policy() {
  return (
    <div className="card card-style">
      <div className="content">
        <h4>Privacy Policy</h4>
        <div className="list-group mt-4 list-custom list-group-m rounded-xs">
          <a className="list-group-item">
            <p>
              Information We Collect: a. Personal Information: When you use PocketKhata, we may
              collect personal information such as your name, email address, phone number, and
              business details. This information is necessary for providing our services and
              enhancing your user experience. b. Financial Information: PocketKhata may require you
              to provide financial information, such as bank account details or payment gateway
              credentials, to enable certain features such as payment processing. We only collect
              and store this information to facilitate transactions and ensure secure financial
              transactions. c. Usage Data: We may collect usage data, including but not limited to
              your interactions with the application, features you use, and the duration of your
              usage. This information helps us understand how you use PocketKhata and improve our
              services.
            </p>
            <p>
              Information We Collect: a. Personal Information: When you use PocketKhata, we may
              collect personal information such as your name, email address, phone number, and
              business details. This information is necessary for providing our services and
              enhancing your user experience. b. Financial Information: PocketKhata may require you
              to provide financial information, such as bank account details or payment gateway
              credentials, to enable certain features such as payment processing. We only collect
              and store this information to facilitate transactions and ensure secure financial
              transactions. c. Usage Data: We may collect usage data, including but not limited to
              your interactions with the application, features you use, and the duration of your
              usage. This information helps us understand how you use PocketKhata and improve our
              services.
            </p>
            <p>
              Data Security: a. We take reasonable measures to protect the security of your personal
              information. However, no method of transmission over the internet or electronic
              storage is completely secure. We cannot guarantee the absolute security of your data,
              and you provide your information at your own risk. b. We restrict access to personal
              information to authorized personnel only and implement security protocols to safeguard
              user data.
            </p>
            <p>
              Data Retention: a. We retain your personal information for as long as necessary to
              fulfill the purposes outlined in this Privacy Policy unless a longer retention period
              is required or permitted by law. b. You may request the deletion of your personal
              information by contacting us through the provided channels. However, please note that
              certain data may be retained for legal, regulatory, or legitimate business purposes.
            </p>
            <p>
              Changes to the Privacy Policy: We reserve the right to modify this Privacy Policy at
              any time. Any changes will be effective immediately upon posting the updated Privacy
              Policy within the PocketKhata application. We encourage you to review this Privacy
              Policy periodically for any updates.
            </p>
            <p>
              Contact Us: If you have any questions, concerns, or requests regarding this Privacy
              Policy or
            </p>
          </a>
        </div>
      </div>
    </div>
  );
}
