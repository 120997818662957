/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import CommonContext from '../../commonContext';
import { httpCall, HTTP_METHODS } from '../../services/httpService';
import { useForm } from 'react-hook-form';
import { formatInputDate } from '../../share/Utils';
import DatePicker from 'react-datepicker';

/* eslint-disable react/react-in-jsx-scope */
function ExpenseForm(props) {
  // eslint-disable-next-line react/prop-types
  const { show, setModalShow, expenseFormValue, categoryData } = props;
  const { contextData, setContextData } = useContext(CommonContext);
  const [expenseDate, setExpenseDate] = useState(new Date());
  const initialFormState = { date: '', amount: '', type: {}, notes: '' };
  const { register, handleSubmit, formState, setValue, reset } = useForm({
    defaultValues: expenseFormValue ? expenseFormValue : initialFormState,
    mode: 'onChange'
  });
  const editFlag = Object.keys(expenseFormValue).length ? true : false;
  useEffect(() => {
    if (editFlag) {
      setValue('type', expenseFormValue.type.uuid);
      setValue('amount', expenseFormValue.amount);
      setExpenseDate(new Date(expenseFormValue.date));
    }
  }, []);

  async function saveExpense(data) {
    data.type = {
      name: categoryData.find((category) => category.uuid === data.type).name,
      uuid: data.type
    };
    // delete data.categoryId;
    data.date = formatInputDate(expenseDate);
    const resp = await httpCall(HTTP_METHODS.POST, 'expense/add', data);
    statusCheck(resp, 'Added');
  }

  async function editExpense(data) {
    data.type = {
      name: categoryData.find((category) => category.uuid === data.type).name,
      uuid: data.type
    };
    data.date = formatInputDate(expenseDate);
    const resp = await httpCall(HTTP_METHODS.POST, `expense/${expenseFormValue.uuid}`, data);
    statusCheck(resp, 'Edited');
  }

  async function deleteExpense() {
    const resp = confirm('Are you sure?');
    if (resp) {
      await httpCall(HTTP_METHODS.DELETE, `expense/${expenseFormValue.uuid}`);
      setContextData({
        ...contextData,
        toast: { show: true, type: 'success', message: 'expense deleted successfully!' }
      });
      setModalShow(false);
    }
  }

  const onSubmit = async (data) => {
    try {
      editFlag ? editExpense(data) : saveExpense(data);
      setModalShow(false);
      reset();
    } catch (error) {
      setContextData({
        ...contextData,
        toast: { show: true, type: 'error', message: error.response.data.message },
        expenseUpdated: true
      });
    }
  };

  const statusCheck = (resp, action) => {
    if (resp.status === 200) {
      setContextData({
        ...contextData,
        toast: { show: true, type: 'success', message: `expense ${action} successfully!` },
        expenseUpdated: true
      });
    } else {
      setContextData({
        ...contextData,
        toast: { show: true, type: 'error', message: resp.message.message },
        expenseUpdated: false
      });
    }
  };

  return (
    <div
      className={`offcanvas offcanvas-modal rounded-m offcanvas-detached bg-theme ${
        show ? 'show' : ''
      }`}
      style={{ width: '340px' }}
      id="menu-login">
      <form className="content" onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex pb-2">
          <div className="align-self-center">
            <h5 className="mb-n1 font-12 color-highlight font-700 text-uppercase pt-1"></h5>
            <h1 className="font-700 mt-2">{editFlag ? 'Edit Expense' : 'Add Expense'}</h1>
          </div>
        </div>
        <div className="form-custom form-label form-icon mb-3">
          <i className="bi bi-calendar font-12"></i>
          <DatePicker
            showIcon
            selected={expenseDate}
            className="form-control rounded-xs"
            onChange={(date) => setExpenseDate(date)}
            placeholderText="Enter Date in dd/mm/yyyy"
            dateFormat="dd/MM/yyyy"
            fixedHeight
            inputProps={{ readOnly: 'isMobile' }}
            onFocus={(e) => (e.target.readOnly = true)}
            disabledKeyboardNavigation
          />
        </div>
        <div className="form-custom form-label form-icon mb-3">
          <i className="bi bi-cash font-14"></i>
          <select
            {...register('type', { required: true })}
            className="form-select rounded-xs"
            id="c6"
            aria-label="Floating label select example">
            <option>SELECT TYPE</option>
            {categoryData?.length &&
              categoryData.map((category) => (
                <option key={category.uuid} value={category.uuid}>
                  {category.name}
                </option>
              ))}
          </select>
        </div>
        <div className="form-custom form-label form-icon mb-3">
          <i className="bi bi-receipt font-14"></i>
          <input
            type="text"
            autoComplete="off"
            className="form-control rounded-xs"
            id="c1"
            placeholder="Amount"
            {...register('amount', { required: true })}
          />
        </div>
        <div className="form-custom form-label form-icon mb-3">
          <i className="bi bi-card-text font-14"></i>
          <input
            type="text"
            autoComplete="off"
            className="form-control rounded-xs"
            id="c1"
            placeholder="Notes"
            {...register('notes', { required: false })}
          />
        </div>
        <button
          style={{ width: '100%' }}
          disabled={!formState.isValid}
          type="submit"
          className="btn btn-full gradient-blue shadow-bg shadow-bg-s mt-4">
          Save
        </button>
        {editFlag && (
          <a
            onClick={() => deleteExpense()}
            style={{ width: '100%' }}
            className="btn btn-full gradient-red shadow-bg shadow-bg-s mt-4">
            Delete
          </a>
        )}
      </form>
    </div>
  );
}

export default ExpenseForm;
