import { useQuery } from 'react-query';
import { httpCall, HTTP_METHODS } from '../services/httpService';
import useText from '../share/hooks/useText';
import moment from 'moment';
import { numberFormat, formatTime } from '../share/Utils';

/* eslint-disable react/react-in-jsx-scope */
export default function Activity() {
  // eslint-disable-next-line no-unused-vars
  useText('Activity', 'activity'); // update context for title and page

  let url = `user/activity`;

  const { data, isLoading, error } = useQuery('bill', fetchData);

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="spinner-border color-highlight" role="status"></div>
      </div>
    );
  }

  if (error) return 'An error has occurred: ' + error.message;

  async function fetchData() {
    const response = await httpCall(HTTP_METHODS.GET, url);
    return response.data;
  }
  return (
    <div className="page">
      <div className="page-content header-clear-medium">
        <div className="card card-style">
          <div className="content m-0">
            {/* <div className="d-flex px-3">
            <div>
              <h5 className="pb-1 pt-3 font-13">Recent Chats</h5>
            </div>
            <div className="ms-auto">
              <h5 className="ps-3 pb-1 pt-3 font-13 color-highlight">3 Unread Messages</h5>
            </div>
          </div> */}
            {/* <div className="divider mb-0 mt-2"></div> */}
            <div className="list-group list-custom list-group-m rounded-l">
              {data?.length &&
                data.map((activity) => (
                  <a
                    key={activity.uuid}
                    className="list-group-item border-top-0 border-start-0 border-end-0">
                    <img
                      src={activity?.user?.image || 'images/pictures/2s.jpg'}
                      width="40"
                      className="me-2 rounded-s"
                    />
                    <i className="bi bi-circle-fill font-10 ps-4 ms-2 mt-3 pt-1 position-absolute color-green-dark"></i>
                    <div className="ps-1">
                      <strong className="font-600">{activity?.user?.name}</strong>
                      <p className="font-400">{`${activity.action} 
                    ${activity?.entity?.type}( ${formatTime(
                        activity?.entity?.date
                      )}) of amount ${numberFormat(activity?.entity?.amount)} for ${
                        activity.customer.name
                      }`}</p>
                    </div>
                    <p className="font-10 font-500 color-theme text-end ms-auto line-height-xs align-self-start pt-3">
                      {moment(activity.updatedAt).format('Do MMM h:mm a')}
                    </p>
                  </a>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
