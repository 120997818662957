/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Row, Col, FloatingLabel, Form, Card, Button } from 'react-bootstrap';
import InvoiceItem from '../components/Invoice/InvoiceItem';
import InvoiceModal from '../components/Invoice/InvoiceModal';
import InputGroup from 'react-bootstrap/InputGroup';
import { httpCall, HTTP_METHODS } from '../services/httpService';
import { Link } from 'react-router-dom';
import { Typeahead, Hint } from 'react-bootstrap-typeahead';
import shortid from 'shortid';

function Invoice(props) {
  const [invoiceState, setInvoiceState] = useState({
    isOpen: false,
    currency: 'Rs. ',
    currentDate: '',
    customer_uuid: '',
    invoiceNumber: shortid.generate(),
    dateOfIssue: '',
    notes: '',
    items: [
      {
        id: 0,
        name: '',
        description: '',
        price: '1',
        quantity: 1
      }
    ]
  });
  const [billState, setBillState] = useState({
    total: '0.00',
    subTotal: '0.00',
    taxRate: '',
    taxAmount: '0.00',
    discountRate: '',
    discountAmount: '0.00',
    paid_amount: '0'
  });
  const [customerData, setCustomerData] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [enableButtonFlag, setEnableButtonFlag] = useState(true);

  useEffect(() => {
    async function fetch() {
      await fetchData();
    }
    fetch();
  }, []);

  function checkButton() {
    if (
      invoiceState.invoiceNumber &&
      selectedCustomer[0]?.uuid &&
      invoiceState.dateOfIssue &&
      invoiceState.items.length &&
      invoiceState.items.every((item) => item.name) &&
      billState.total
    ) {
      setEnableButtonFlag(false);
    } else {
      setEnableButtonFlag(true);
    }
  }

  async function fetchData() {
    const user = JSON.parse(localStorage.getItem('user'));
    const response = await httpCall(HTTP_METHODS.GET, 'customer');
    const customerVal = response.data.map((cus) => {
      return {
        ...cus,
        label: cus.name
      };
    });
    setCustomerData(customerVal);
    const resp = await httpCall(HTTP_METHODS.GET, `organization/${user.orgId}`);
    setOrgData(resp.data);
  }

  useEffect(() => {
    if (invoiceState?.taxRate || invoiceState?.discountRate) {
      const { taxRate = 0, discountRate = 0 } = invoiceState;
      const items = [...invoiceState.items];
      let subTotal = 0;

      items.map(({ price, quantity }) => {
        subTotal += parseFloat(price || 0) * parseInt(quantity || 0);
      });

      const finalPrice = parseFloat(subTotal) * (1 + taxRate / 100);
      const discountAmount = (parseFloat(subTotal) * discountRate) / 100;

      if (invoiceState.paid_amount > finalPrice - discountAmount) {
        alert('Paid Amount is more than Total');
      }

      setBillState({
        ...billState,
        taxRate: taxRate,
        discountRate: discountRate,
        discountAmount: parseFloat(discountAmount).toFixed(2) || 0,
        subTotal: parseFloat(subTotal).toFixed(2),
        taxAmount: parseFloat((parseFloat(subTotal) * taxRate) / 100).toFixed(2),
        total: parseFloat(finalPrice - discountAmount).toFixed(2),
        paid_amount: invoiceState.paid_amount
      });
    }
    checkButton();
  }, [invoiceState.taxRate, invoiceState?.discountRate, invoiceState?.paid_amount]);

  const handleRowDel = (items) => {
    const index = invoiceState.items.indexOf(items);
    const invoiceStateItem = [...invoiceState.items];
    invoiceStateItem.splice(index, 1);
    setInvoiceState({
      ...invoiceState,
      items: invoiceStateItem
    });
    invoiceCalculator(invoiceStateItem);
    checkButton();
  };

  const handleAddEvent = (evt) => {
    const id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
    const items = {
      id: id,
      name: '',
      price: '1',
      description: '',
      quantity: 1
    };
    const invoiceStateItem = [...invoiceState.items];
    invoiceStateItem.push(items);
    setInvoiceState({
      ...invoiceState,
      items: invoiceStateItem
    });
  };

  const handleCalculateTotal = () => {
    const items = [...invoiceState.items];
    invoiceCalculator(items);
    checkButton();
  };

  const invoiceCalculator = (items) => {
    let subTotal = 0;

    items.map(({ price, quantity }) => {
      subTotal += parseFloat(price || 0) * parseInt(quantity || 0);
    });
    setBillState({
      ...billState,
      subTotal: parseFloat(subTotal).toFixed(2),
      taxAmount:
        billState.taxRate &&
        parseFloat(parseFloat(subTotal) * (billState.taxRate / 100)).toFixed(2),
      discountAmount:
        billState.discountRate &&
        parseFloat(parseFloat(subTotal) * (billState.discountRate / 100)).toFixed(2),
      total: Math.ceil(
        subTotal - (billState.discountAmount || 0) + parseFloat(billState.taxAmount || 0)
      ),
      paid_amount: invoiceState.paid_amount
    });
  };

  const onItemizedItemEdit = (evt) => {
    const item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value
    };
    const items = invoiceState.items.slice();
    const newItems = items.map(function (items) {
      for (var key in items) {
        if (key == item.name && items.id == item.id) {
          items[key] = item.value;
        }
      }
      return items;
    });
    setInvoiceState({ ...invoiceState, items: newItems });
    handleCalculateTotal();
  };

  const editField = (event) => {
    setInvoiceState({
      ...invoiceState,
      [event.target.name]: event.target.value
    });
    handleCalculateTotal();
  };

  const openModal = (event) => {
    event.preventDefault();
    handleCalculateTotal();
    setInvoiceState({ ...invoiceState, isOpen: true });
  };

  const closeModal = (event) => setInvoiceState({ ...invoiceState, isOpen: false });

  const onSubmit = async () => {
    let url = `invoice/add`;
    const reqBody = {
      invoice_number: invoiceState.invoiceNumber,
      invoice_date: invoiceState.dateOfIssue,
      status: +(billState.total || 0) - +(billState.paid_amount || 0) === 0 ? 'paid' : 'unpaid',
      sub_total_amount: billState.subTotal,
      paid_amount: +billState.paid_amount || 0,
      due_amount: +(billState.total || 0) - +(billState.paid_amount || 0),
      total_amount: billState.total,
      customer_uuid: selectedCustomer[0]?.uuid,
      discount: billState.discountAmount,
      products: billState.items
    };
    const response = await httpCall(HTTP_METHODS.POST, url, reqBody);
    console.log('response', reqBody);
  };

  return (
    <div id="page">
      <div className="page-content header-clear-medium">
        <div className="card card-style">
          <div className="content">
            <Link to={'/invoice'}>Back</Link>
            <Form>
              <Row>
                <Col md={8} lg={9}>
                  <Card className="p-4 p-xl-5 my-3 my-xl-4">
                    <div className="d-flex flex-row align-items-start justify-content-between mb-3">
                      <div className="d-flex flex-column">
                        <div className="mb-2">
                          <span className="fw-bold">Current&nbsp;Date:&nbsp;</span>
                          <span className="current-date">{new Date().toLocaleDateString()}</span>
                        </div>
                        <div className="d-flex flex-row align-items-center">
                          <span className="fw-bold d-block me-2">Due&nbsp;Date:</span>
                          <Form.Control
                            type="date"
                            value={invoiceState.dateOfIssue}
                            name={'dateOfIssue'}
                            onChange={(event) => editField(event)}
                            style={{
                              maxWidth: '150px'
                            }}
                            required="required"
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-column">
                        <h2>{orgData.name}</h2>
                        <p>{orgData.place}</p>
                      </div>
                      <div className="d-flex">
                        <span className="fw-bold me-1">Invoice&nbsp;Number:&nbsp;</span>
                        {invoiceState.invoiceNumber}
                      </div>
                    </div>
                    <hr className="my-4" />
                    <Row className="mb-5">
                      <Col md={9}>
                        <Typeahead
                          id="floating-label-example"
                          onChange={setSelectedCustomer}
                          options={customerData}
                          placeholder="Choose a customer..."
                          renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                            return (
                              <Hint>
                                <FloatingLabel
                                  controlId="floatingLabel"
                                  label="Choose a customer...">
                                  <Form.Control
                                    {...inputProps}
                                    ref={(node) => {
                                      inputRef(node);
                                      referenceElementRef(node);
                                    }}
                                  />
                                </FloatingLabel>
                              </Hint>
                            );
                          }}
                          selected={selectedCustomer}
                        />
                      </Col>
                      <Col md={3}>
                        <Link
                          to={'/addCustomer'}
                          state={{ page: 'invoice' }}
                          variant="primary"
                          className="btn bg-blue-dark border-blue-dark">
                          Add Customer
                        </Link>
                      </Col>
                    </Row>
                    <InvoiceItem
                      onItemizedItemEdit={onItemizedItemEdit}
                      onRowAdd={handleAddEvent}
                      onRowDel={handleRowDel}
                      currency={invoiceState.currency}
                      items={invoiceState.items}
                    />
                    <Row className="mt-4 justify-content-end">
                      <Col lg={6}>
                        <div className="d-flex flex-row align-items-start justify-content-between">
                          <span className="fw-bold">Subtotal:</span>
                          <span>
                            {invoiceState.currency}
                            {billState.subTotal}
                          </span>
                        </div>
                        <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                          <span className="fw-bold">Discount:</span>
                          <span>
                            <span className="small ">({billState.discountRate || 0}%)</span>
                            {invoiceState.currency}
                            {billState.discountAmount || 0}
                          </span>
                        </div>
                        <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                          <span className="fw-bold">Tax:</span>
                          <span>
                            <span className="small ">({billState.taxRate || 0}%)</span>
                            {invoiceState.currency}
                            {billState.taxAmount || 0}
                          </span>
                        </div>
                        <hr />
                        <div
                          className="d-flex flex-row align-items-start justify-content-between"
                          style={{
                            fontSize: '1.125rem'
                          }}>
                          <span className="fw-bold">Total:</span>
                          <span className="fw-bold">
                            {invoiceState.currency}
                            {billState.total || 0}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <hr className="my-4" />
                    <Form.Label className="fw-bold">Notes:</Form.Label>
                    <Form.Control
                      placeholder="Thanks for your business!"
                      name="notes"
                      value={invoiceState.notes}
                      onChange={(event) => editField(event)}
                      as="textarea"
                      className="my-2"
                      rows={1}
                    />
                  </Card>
                </Col>
                <Col md={4} lg={3}>
                  <div className="sticky-top pt-md-3 pt-xl-4">
                    <Button
                      onClick={openModal}
                      disabled={enableButtonFlag}
                      variant="primary"
                      className="d-block w-100">
                      Review Invoice
                    </Button>
                    <Button
                      onClick={onSubmit}
                      disabled={enableButtonFlag}
                      variant="success"
                      className="d-block w-100 mt-2">
                      Save
                    </Button>
                    {invoiceState.isOpen && (
                      <InvoiceModal
                        showModal={invoiceState.isOpen}
                        closeModal={closeModal}
                        selectedCustomer={selectedCustomer[0]}
                        orgData={orgData}
                        info={invoiceState}
                        items={invoiceState.items}
                        currency={invoiceState.currency}
                        subTotal={billState.subTotal}
                        paidAmount={billState.paid_amount}
                        taxAmount={billState.taxAmount}
                        discountAmount={billState.discountAmount}
                        total={billState.total}
                        onSubmit={onSubmit}
                      />
                    )}
                    <Form.Group className="my-3">
                      <Form.Label className="fw-bold">Tax rate:</Form.Label>
                      <InputGroup className="my-1 flex-nowrap">
                        <Form.Control
                          name="taxRate"
                          type="number"
                          value={billState.taxRate}
                          onChange={(event) => editField(event)}
                          className="bg-white border"
                          placeholder="0"
                          min="0"
                          step="1"
                          max="100"
                        />
                        <InputGroup.Text className="bg-light fw-bold text-secondary small">
                          %
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="my-3">
                      <Form.Label className="fw-bold">Discount rate:</Form.Label>
                      <InputGroup className="my-1 flex-nowrap">
                        <Form.Control
                          name="discountRate"
                          type="number"
                          value={billState.discountRate}
                          onChange={(event) => editField(event)}
                          className="bg-white border"
                          placeholder="0"
                          min="0"
                          step="1"
                          max="100"
                        />
                        <InputGroup.Text className="bg-light fw-bold text-secondary small">
                          %
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="my-3">
                      <Form.Label className="fw-bold">Paid Amount:</Form.Label>
                      <InputGroup className="my-1 flex-nowrap">
                        <Form.Control
                          name="paid_amount"
                          type="number"
                          value={billState.paid_amount}
                          onChange={(event) => editField(event)}
                          className="bg-white border"
                          placeholder="0"
                        />
                      </InputGroup>
                    </Form.Group>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Invoice;
