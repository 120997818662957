import { useContext, useEffect } from 'react';
import CommonContext from '../../commonContext';

const useText = (title, page) => {
  const { contextData, setContextData } = useContext(CommonContext);
  useEffect(() => {
    setContextData({ ...contextData, title, page });
  }, []);

  return { contextData, setContextData };
};

export default useText;
