/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react';
import { httpCall, HTTP_METHODS } from '../services/httpService';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import avatar from '../images/avatars/2s.png';
import { formatTime, numberFormat } from '../share/Utils';
import useText from '../share/hooks/useText';
import moment from 'moment';

function Product() {
  useText('Products', 'product'); // update context for title and page

  const { data: productData, isLoading, error, refetch } = useQuery('product', fetchData);
  const [totalAmount, setTotalAmount] = useState(0);
  const [filteredDataValue, setFilteredDataValue] = useState([]);

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="spinner-border color-highlight" role="status"></div>
      </div>
    );
  }

  if (error) return 'An error has occurred: ' + error.message;

  async function fetchData() {
    const response = await httpCall(HTTP_METHODS.GET, 'product');
    return response.data;
  }

  function filterData(event) {
    if (productData) {
      const filteredDataV = productData.filter((product) => {
        return product.product_name.toLowerCase().includes(event.target.value.toLowerCase());
      });
      setFilteredDataValue(filteredDataV);
    }
  }

  return (
    <div id="page">
      <div className="page-content header-clear-medium">
        <div className="card card-style">
          <div className="content">
            <div className="form-custom form-label form-icon mb-3">
              <i className="color-blue-dark bi bi-search"></i>
              <input
                type="text"
                onChange={filterData}
                className="form-control rounded-xs"
                id="c1"
                placeholder={`${filteredDataValue?.length || productData?.length} products`}
              />
            </div>
            <div className="list-group list-custom list-group-m rounded-m">
              {filteredDataValue.length > 0
                ? filteredDataValue.map((product) => (
                    <div key={product.uuid} className="list-group-item row">
                      <div className="col-7">
                        <div className="ps-1">
                          <strong className="font-600">{product.product_name}</strong>
                          <span className="font-600">{`${product.company}`}</span>
                        </div>
                      </div>
                      <span className="col-3 font-800">{product.expiry}</span>
                      <div className="col-2" style={{ color: 'green' }}>
                        {product.stock}
                      </div>
                    </div>
                  ))
                : productData &&
                  productData.map((product) => (
                    <div key={product.uuid} className="list-group-item row">
                      <div className="col-7">
                        <div className="ps-1">
                          <strong className="font-600">{product.product_name}</strong>
                          <span className="font-600">{`${product.company}`}</span>
                        </div>
                      </div>
                      <span className="col-3">{product.expiry}</span>
                      <div className="col-2" style={{ color: 'green' }}>
                        {product.stock}
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Product;
