/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import { useContext, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink, useNavigate, useLocation, Link, json } from 'react-router-dom';
import CommonContext from '../commonContext';
import BillForm from './modals/BillForm';
import PaymentForm from './modals/PaymentForm';

/* eslint-disable react/react-in-jsx-scope */
function Footer(props) {
  const { addShowClass } = props
  const [billModalShow, setBillModalShow] = useState(false);
  const [paymentModalShow, setPaymentModalShow] = useState(false);
  const location = useLocation();
  const pathName = location.pathname
  const { contextData } = useContext(CommonContext)
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'))
  return (
    <>
      {contextData.page !== 'profile' && user && (
        <Nav id="footer-bar" className="footer-bar footer-bar-detached">
          <NavLink to="/customer" className={pathName === '/customer' ? 'active-nav' : ''}>
            <i className="bi bi-people font-17"></i>
            <span>Customer</span>
          </NavLink>
          <NavLink to="/bill" className={pathName === '/bill' ? 'active-nav' : ''}>
            <i className="bi bi-receipt font-16"></i>
            <span>Bill</span>
          </NavLink>
          <NavLink to="/payment" className={pathName === '/payment' ? 'active-nav' : ''}>
            <i className="bi bi-cash font-16"></i>
            <span>Payment</span>
          </NavLink>
          {/* <NavLink to="/report" className={pathName === '/dashboard' ? 'active-nav' : ''}>
            <i className="bi bi-heart-fill font-15"></i>
            <span>Report</span>
          </NavLink> */}
          <NavLink to="/dashboard" className={pathName === '/dashboard' ? 'active-nav' : ''}>
            <i className="bi bi-heart-fill font-15"></i>
            <span>Dashboard</span>
          </NavLink>
          {user?.roleName === 'Admin' && (
            <NavLink
              to="/activity"
              className={pathName === '/activity' ? 'active-nav' : ''}
              data-bs-toggle="offcanvas"
              data-bs-target="#menu-main">
              <i className="bi bi-activity"></i>
              <span>Activity</span>
            </NavLink>
          )}
        </Nav>
      )}
      {contextData.page === 'profile' && user && (
        <div id="footer-bar" className="row footer-bar footer-bar-detached">
          {user?.features?.includes('bill') && <div className="col">
            <a onClick={() => setBillModalShow(true)} className="btn-full btn bg-red-dark">
              +Bill
            </a>
          </div>}
          <div className="col mb-4 pb-1">
            <Link
              to={"/customer"}
              className="btn-full btn bg-blue-dark">
                Back
            </Link>
          </div>
          <div className="col">
            <a onClick={() => setPaymentModalShow(true)} className="btn-full btn bg-green-dark">
              +Payment
            </a>
          </div>
        </div>
      )}
        {contextData.page === 'company-profile' && user && (
        <div id="footer-bar" className="row footer-bar footer-bar-detached">
          {user?.roleName === 'Admin' && <div className="col">
            <Link to={`company/${location.pathname.slice(9)}/addBill`} className="btn-full btn bg-red-dark">
              +Bill
            </Link>
          </div>}
          <div className="col mb-4 pb-1">
            <Link
              to={"/company"}
              className="btn-full btn bg-blue-dark">
                Back
            </Link>
          </div>
          <div className="col">
            <Link to={`company/${location.pathname.slice(9)}/addPayment`} className="btn-full btn bg-green-dark">
              +Payment
            </Link>
          </div>
        </div>
      )}
      <BillForm
        show={billModalShow}
        setModalShow={setBillModalShow}
        onHide={() => setBillModalShow(false)}
      />
      {billModalShow && (
        <div onClick={() => setBillModalShow(false)} className="offcanvas-backdrop fade show"></div>
      )}
      <PaymentForm
        show={paymentModalShow}
        setModalShow={setPaymentModalShow}
        onHide={() => setPaymentModalShow(false)}
      />
      {paymentModalShow && (
        <div
          onClick={() => setPaymentModalShow(false)}
          className="offcanvas-backdrop fade show"></div>
      )}
    </>
  );
}

export default Footer;
