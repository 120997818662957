/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import Payment from './Payment';
import Bill from './Bill';
import CombinedData from '../components/CombinedData';
import CommonContext from '../commonContext';
import BillForm from '../components/modals/BillForm';
import PaymentForm from '../components/modals/PaymentForm';
import { httpCall, HTTP_METHODS } from '../services/httpService';
import { useQuery } from 'react-query';
import ActionSheet from '../UI/ActionSheet';
import { numberFormat } from '../share/Utils';
import { BACKEND_URL_PROD } from '../share/Constant';
// import Report from './Report';

// import { numberFormat } from '../share/Utils';

function CustomerProfile() {
  const { state } = useLocation();
  const [actionSheetShow, setActionSheetShow] = useState(false);
  const [billModalShow, setBillModalShow] = useState(false);
  const [billFormValue, setBillFormValue] = useState({});
  const [paymentModalShow, setPaymentModalShow] = useState(false);
  const [paymentFormValue, setPaymentFormValue] = useState({});
  const [customerBillData, setCustomerBillData] = useState([]);
  const [customerPaymentData, setCustomerPaymentData] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const { refetch, data, isLoading, error } = useQuery('customerLedger', fetchData);
  const params = useParams();
  const [netBalance, setNetBalance] = useState({});
  const [customer, setCustomer] = useState({});
  const { contextData, setContextData } = useContext(CommonContext);

  // const customer = state?.customer;
  // useEffect(() => {
  //   setContextData({
  //     ...contextData,
  //     page: 'profile'
  //     // title: customer ? `${customer?.name?.slice(0, 11)}(${customer?.place.name})` : 'Customers'
  //   });
  // }, []);
  const [tabName, setTabName] = useState('Ledger');
  const [showAction, setShowAction] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [htmlString, setHtmlString] = useState('');

  useEffect(() => {
    if (contextData.billUpdated || contextData.paymentUpdated || dateRange[0]) {
      refetch();
    }
    return () => {
      setContextData({
        ...contextData,
        billUpdated: false,
        paymentUpdated: false
      });
    };
  }, [contextData.billUpdated, contextData.paymentUpdated, dateRange[0]]);

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="spinner-border color-highlight" role="status"></div>
      </div>
    );
  }

  if (error) return 'An error has occurred: ' + error.message;

  async function fetchData() {
    let url = `customer/ledger?customer_uuid=${params.id}`;
    if (dateRange.length) {
      url = `customer/ledger?customer_uuid=${params.id}&start_date=${dateRange[0]}&end_date=${dateRange[1]}`;
    }
    const response = await httpCall(HTTP_METHODS.GET, url);
    setNetBalance(response.totalAmount);
    if (response?.data) {
      const billData = response.data.filter((val) => val.type === 'bill');
      setCustomerBillData(billData);
      setCustomerPaymentData(response.data.filter((val) => val.type === 'payment'));
    }
    if (response.entity) {
      setCustomer(response.entity);
    }
    return response.data;
  }

  function showActions() {
    setShowAction(!showAction);
  }

  async function fetchReport(e) {
    e.preventDefault();
    const response = await httpCall(
      HTTP_METHODS.GET,
      `customer/ledger/public?customer_uuid=${customer.uuid}`
    );
    setShowAction(false);
    // setHtmlString(response.data);
  }

  return (
    <>
      <div id="page">
        <div className="page-content header-clear-medium">
          <div className="card card-style">
            <div className="mt-1">
              {/* <div className="row p-3">
                <h4 className="col-5">
                  Dues : - <span className="color-red-light">{numberFormat(netBalance)}</span>
                </h4>
                <div className="d-flex col-7">
                  <div className="col">
                    {customer?.phoneNumber && (
                      <a
                        href={`tel:${customer?.phoneNumber}`}
                        className="btn bg-yellow-dark shadow-bg-m btn-xs">
                        Call
                      </a>
                    )}
                  </div>
                  <div
                    style={{ marginRight: '2px' }}
                    className="col"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${BACKEND_URL_PROD}/customer/ledger/public?customer_uuid=${customer.uuid}`
                      );
                    }}>
                    <a className="btn bg-green-dark shadow-bg-m btn-xs">Copy</a>
                  </div>
                  <div className="col">
                    <Link
                      style={{ height: 'fit-content' }}
                      to={'/addCustomer'}
                      state={{ customer }}
                      className="btn bg-blue-dark shadow-bg-m btn-xs">
                      Edit
                    </Link>
                  </div>
                </div>
              </div> */}
              <div className="bg-theme mx-3 rounded-m shadow-m mt-2 mb-1">
                <div className="d-flex pt-2">
                  {/* <div>
                    <a href="#">
                      <img
                        src="images/pictures/7s.jpg"
                        width="45"
                        className="rounded-s"
                        alt="img"
                      />
                    </a>
                  </div> */}
                  <div className="ps-2" style={{ width: '90%' }}>
                    {/* <h5 className="ps-1 mb-1 font-weight-bold">{customer?.name}</h5>
                    <h6 className="ps-1 mb-0 font-italic opacity-80 font-12">{customer?.area}</h6> */}
                    <table className="table mt-3 table-borderless">
                      <thead>
                        <tr>
                          <th scope="col">CATEGORY</th>
                          <th className="color-green-light" scope="col">
                            SALE
                          </th>
                          <th className="color-red-light" scope="col">
                            DUES
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>ALL</td>
                          <td>{numberFormat(netBalance.totalBillAmount)}</td>
                          <td>
                            {numberFormat(
                              netBalance.totalBillAmount - netBalance.totalPaymentAmount
                            )}
                          </td>
                        </tr>
                        {customer.mankindFlag && (
                          <tr>
                            <td>MANKIND</td>
                            <td>{numberFormat(netBalance.totalMankindBillAmt)}</td>
                            <td>
                              {numberFormat(
                                netBalance.totalMankindBillAmt - netBalance.totalMankindPaymentAmt
                              )}
                            </td>
                          </tr>
                        )}
                        {netBalance.monthlyData &&
                          netBalance.monthlyData.map((month) => (
                            <tr key={month.month}>
                              <td>{month.month}</td>
                              <td>{numberFormat(month.bill_amount || 0)}</td>
                              <td>
                                {numberFormat(
                                  (month.bill_amount || 0) - (month.payment_amount || 0)
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="ms-auto">
                    <a onClick={showActions} data-bs-toggle="dropdown" className="icon icon-m ps-3">
                      <i className="bi bi-three-dots-vertical font-18 color-theme"></i>
                    </a>
                    <div
                      style={{ marginLeft: '-110px' }}
                      className={`dropdown-menu  bg-transparent border-0 mt-n1 ${
                        showAction ? 'show' : ''
                      }`}>
                      <div className="card card-style rounded-m shadow-xl mt-1 me-1">
                        <div className="list-group list-custom list-group-s list-group-flush rounded-xs px-3 py-1">
                          {customer?.phoneNumber && (
                            <a
                              href={`tel:${customer?.phoneNumber}`}
                              className="color-theme opacity-70 list-group-item py-1">
                              <i className="bi bi-telephone-fill color-blue-dark font-15"></i>
                              <strong className="font-500 font-12">Call</strong>
                            </a>
                          )}
                          <Link
                            style={{ height: 'fit-content' }}
                            to={'/addCustomer'}
                            state={{ customer }}
                            className="color-theme opacity-70 list-group-item py-1">
                            <i className="bi bi-pencil-fill color-blue-dark font-15"></i>
                            <strong className="font-500 font-12">Edit Customer</strong>
                          </Link>
                          <a
                            href={
                              customer.phoneNumber
                                ? `whatsapp://send?text=${BACKEND_URL_PROD}customer/ledger/public?customer_uuid=${customer.uuid}&phone=+91${customer.phoneNumber}`
                                : `whatsapp://send?text=${BACKEND_URL_PROD}customer/ledger/public?customer_uuid=${customer.uuid}`
                            }
                            className="color-theme opacity-70 list-group-item py-1">
                            <i className="bi bi-share-fill color-blue-dark font-15"></i>
                            <strong className="font-500 font-12">Share Report</strong>
                          </a>
                          <a
                            // onClick={fetchReport}
                            href={`${BACKEND_URL_PROD}/customer/ledger/public?customer_uuid=${customer.uuid}`}
                            className="color-theme opacity-70 list-group-item py-1">
                            <i className="bi bi-download color-blue-dark font-15"></i>
                            <strong className="font-500 font-12">Report</strong>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {Object.keys(customer).length && (
                <CombinedData
                  setBillModalShow={setBillModalShow}
                  setPaymentModalShow={setPaymentModalShow}
                  setPaymentFormValue={setPaymentFormValue}
                  setBillFormValue={setBillFormValue}
                  data={data}
                  netBalance={netBalance}
                  setActionSheetShow={setActionSheetShow}
                  customerData={customer}
                />
              )}
            </div>
          </div>
        </div>
        {/* {htmlString && <HtmlStringToPdf htmlString={htmlString} />} */}
      </div>
      <BillForm
        show={billModalShow}
        billFormValue={billFormValue}
        setModalShow={setBillModalShow}
        customerMankindFlag={customer}
        onHide={() => setBillModalShow(false)}
      />
      {billModalShow && (
        <div onClick={() => setBillModalShow(false)} className="offcanvas-backdrop fade show"></div>
      )}
      <PaymentForm
        show={paymentModalShow}
        paymentFormValue={paymentFormValue}
        setModalShow={setPaymentModalShow}
        customerMankindFlag={customer?.mankindFlag}
        onHide={() => setPaymentModalShow(false)}
      />
      {paymentModalShow && (
        <div
          onClick={() => setPaymentModalShow(false)}
          className="offcanvas-backdrop fade show"></div>
      )}
      {actionSheetShow && (
        <ActionSheet
          setDateRange={setDateRange}
          dateRange={dateRange}
          show={actionSheetShow}
          setActionSheetShow={setActionSheetShow}
        />
      )}
      {actionSheetShow && (
        <div
          onClick={() => setActionSheetShow(false)}
          className="offcanvas-backdrop fade show"></div>
      )}
    </>
  );
}

export default CustomerProfile;
