export default function swDev() {
  // let swUrl = `${}/sw.js`
  function determineAppServerKey() {
    const vapidPublicKey =
      'BOUAf1vOkCm6DiLNE4td-Q4pNu2CiLmJKKm5o-VBZTe_oqhF2OVrcdLTNz06x7HTbnrvGtRJEcCuYzu_SGj2yOU';
    return urlBase64ToUint8Array(vapidPublicKey);
  }

  function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    // eslint-disable-next-line no-useless-escape
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  navigator.serviceWorker.register('/sw.js').then((resp) => {
    console.warn('resp', resp);
    if (resp.installing) {
      console.log('Service worker installing');
    } else if (resp.waiting) {
      console.log('Service worker installed');
    } else if (resp.active) {
      console.log('Service worker active');
    }

    if (!('PushManager' in window)) {
      console.log("Push messaging isn't supported.");
      return;
    }
    //
    if (Notification.permission === 'denied') {
      console.log('The user has blocked notifications.');
      return;
    }
    resp.pushManager.getSubscription().then(() => {
      resp.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: determineAppServerKey()
      });
    });
  });
}
