/* eslint-disable react/prop-types */
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

function GenerateInvoice() {
  html2canvas(document.querySelector('#invoiceCapture')).then((canvas) => {
    const imgData = canvas.toDataURL('image/png', 1.0);
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'pt',
      format: [612, 792]
    });
    pdf.internal.scaleFactor = 1;
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('invoice-001.pdf');
  });
}

function InvoiceModal(props) {
  return (
    <div>
      <Modal show={props.showModal} onHide={props.closeModal} size="lg" centered>
        <div id="invoiceCapture">
          <div className="d-flex flex-row justify-content-between align-items-start bg-light w-100 p-4">
            <div className="w-100">
              <h4 className="fw-bold my-2">{props.orgData.name || ''}</h4>
              <h6 className="fw-bold text-secondary mb-1">
                Invoice #: {props.info.invoiceNumber || ''}
              </h6>
            </div>
            <div className="text-end ms-4">
              <h6 className="fw-bold mt-1 mb-2">Amount&nbsp;Due:</h6>
              <h5 className="fw-bold text-secondary">
                {' '}
                {props.currency} {props.total - (props.paidAmount || 0)}
              </h5>
            </div>
          </div>
          <div className="p-4">
            <Row className="mb-4">
              <Col md={4}>
                <div className="fw-bold">Billed to:</div>
                <div>{props.selectedCustomer.name || ''}</div>
                <div>{props.selectedCustomer.area || ''}</div>
                <div>{props.selectedCustomer?.place.name || ''}</div>
              </Col>
              <Col md={4}>
                <div className="fw-bold">Billed From:</div>
                <div>{props.orgData.name || ''}</div>
                <div>{props.orgData.place || ''}</div>
              </Col>
              <Col md={4}>
                <div className="fw-bold mt-2">Date Of Issue:</div>
                <div>{props.info.dateOfIssue || ''}</div>
              </Col>
            </Row>
            <Table className="mb-0">
              <thead>
                <tr>
                  <th>QTY</th>
                  <th>NAME</th>
                  <th className="text-end">PRICE</th>
                  <th className="text-end">AMOUNT</th>
                </tr>
              </thead>
              <tbody>
                {props.items.map((item, i) => {
                  return (
                    <tr id={i} key={i}>
                      <td style={{ width: '70px' }}>{item.quantity}</td>
                      <td>
                        {item.name}
                        {/* - {item.description} */}
                      </td>
                      <td className="text-end" style={{ width: '100px' }}>
                        {props.currency} {item.price}
                      </td>
                      <td className="text-end" style={{ width: '100px' }}>
                        {props.currency} {item.price * item.quantity}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Table>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr className="text-end">
                  <td></td>
                  <td className="fw-bold" style={{ width: '100px' }}>
                    SUBTOTAL
                  </td>
                  <td className="text-end" style={{ width: '100px' }}>
                    {props.currency} {props.subTotal}
                  </td>
                </tr>
                {props.taxAmmount != 0.0 && (
                  <tr className="text-end">
                    <td></td>
                    <td className="fw-bold" style={{ width: '100px' }}>
                      TAX
                    </td>
                    <td className="text-end" style={{ width: '100px' }}>
                      {props.currency} {props.taxAmmount || 0}
                    </td>
                  </tr>
                )}
                {props.discountAmmount != 0.0 && (
                  <tr className="text-end">
                    <td></td>
                    <td className="fw-bold" style={{ width: '100px' }}>
                      DISCOUNT
                    </td>
                    <td className="text-end" style={{ width: '100px' }}>
                      {props.currency} {props.discountAmmount || 0}
                    </td>
                  </tr>
                )}
                <tr className="text-end">
                  <td></td>
                  <td className="fw-bold" style={{ width: '100px' }}>
                    TOTAL
                  </td>
                  <td className="text-end" style={{ width: '100px' }}>
                    {props.currency} {props.total}
                  </td>
                </tr>
              </tbody>
            </Table>
            {props.info.notes && (
              <div className="bg-light py-3 px-4 rounded">{props.info.notes}</div>
            )}
          </div>
        </div>
        <div className="pb-4 px-4">
          <Row>
            {/* <Col md={6}></Col> */}
            {/* <Col md={6}>
                  <Button variant="primary" className="d-block w-100" onClick={GenerateInvoice}>
                    Send Invoice
                  </Button>
                </Col> */}
            <Col md={12}>
              <Button
                variant="primary"
                className="d-block w-100 mt-3 mt-md-0"
                onClick={() => {
                  GenerateInvoice();
                  props.onSubmit();
                }}>
                {/* <BiCloudDownload
                      style={{ width: '16px', height: '16px', marginTop: '-3px' }}
                      className="me-2"
                    /> */}
                Download Copy
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
      <hr className="mt-4 mb-3" />
    </div>
  );
}

export default InvoiceModal;
