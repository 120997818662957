/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
// import { subscribeUser } from '../subscription';

import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import CommonContext from '../commonContext';

export default function Header(props) {
  const { contextData, setContextData } = useContext(CommonContext);
  const { addShowClass, setThemeData } = props;
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  function logout() {
    const resp = confirm('Are you sure?');
    if (resp) {
      localStorage.clear();
      setContextData({
        title: 'pocketKhata',
        page: 'pocketKhata',
        toast: {
          type: '',
          show: false,
          message: ''
        },
        token: '',
        user: ''
      });
      navigate('/login');
    }
  }
  return (
    <div className="header-bar header-fixed header-app header-bar-detached">
      <a
        data-bs-toggle="offcanvas"
        className="show"
        onClick={token && addShowClass}
        data-bs-target="#menu-main"
        href="#">
        <i className="bi bi-list color-theme"></i>
      </a>
      <a className="header-title color-theme color-teal">{contextData.title}</a>
      {/* <a onClick={subscribeUser} className="btn-full btn gradient-blue shadow-bg shadow-bg-m">
        subscribe
      </a> */}

      {token && (
        <a onClick={logout} data-bs-toggle="offcanvas" data-bs-target="#menu-color" href="#">
          <i className="bi bi-person-fill font-13 color-highlight"></i>
        </a>
      )}
      <a onClick={setThemeData} className="show-on-theme-light" data-toggle-theme>
        <i className="bi bi-moon-fill font-13"></i>
      </a>
      <a onClick={setThemeData} className="show-on-theme-dark" data-toggle-theme>
        <i className="bi bi-lightbulb-fill color-yellow-dark font-13"></i>
      </a>
    </div>
  );
}
