/* eslint-disable react/react-in-jsx-scope */
function Table() {
  return (
    <div className="card overflow-visible card-style">
      <div className="content mb-0">
        <h4>classNameic Table</h4>
        <p>
          The default table with values calculated for screen width. Icons, colors and fonts
          supported.
        </p>
        <div className="table-responsive">
          <table className="table color-theme mb-2">
            <thead>
              <tr>
                <th className="border-fade-blue" scope="col">
                  System
                </th>
                <th className="border-fade-blue" scope="col">
                  Share
                </th>
                <th className="border-fade-blue text-center" scope="col">
                  Support
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-fade-blue">
                <td>Apple iOS</td>
                <td>35%</td>
                <td className="text-center">
                  <i className="bi bi-check-circle-fill color-green-dark"></i>
                </td>
              </tr>
              <tr className="border-fade-blue">
                <td>Google Android</td>
                <td>65%</td>
                <td className="text-center">
                  <i className="bi bi-check-circle-fill color-green-dark"></i>
                </td>
              </tr>
              <tr className="border-fade-blue">
                <td className="border-0">Windows Mobile</td>
                <td className="border-0">0%</td>
                <td className="border-0 text-center">
                  <i className="bi bi-x-circle-fill color-red-dark"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Table;
