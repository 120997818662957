/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react';
import { httpCall, HTTP_METHODS } from '../services/httpService';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import avatar from '../images/avatars/2s.png';
import { formatTime, numberFormat } from '../share/Utils';
import useText from '../share/hooks/useText';
import moment from 'moment';

function Company() {
  useText('Companies', 'company'); // update context for title and page

  const { data: companyData, isLoading, error, refetch } = useQuery('company', fetchData);
  const placeData = JSON.parse(localStorage.getItem('places'));
  const [totalAmount, setTotalAmount] = useState(0);
  const [placeId, setPlaceId] = useState('all');
  const [companyName, setCompanyName] = useState('');
  const user = JSON.parse(localStorage.getItem('user'));
  const isAdmin = user?.roleName === 'Admin';
  const [filteredDataValue, setFilteredDataValue] = useState([]);

  useEffect(() => {
    filterData();
  }, [companyData, placeId, companyName]);

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="spinner-border color-highlight" role="status"></div>
      </div>
    );
  }

  if (error) return 'An error has occurred: ' + error.message;

  async function fetchData() {
    const response = await httpCall(HTTP_METHODS.GET, 'company');
    setTotalAmount(response.totalAmount);
    return response.data;
  }

  function filterData() {
    if (companyData) {
      const filteredData = companyData.filter((company) => {
        const isCompanyNameMatch = company.name.toLowerCase().includes(companyName.toLowerCase());
        return isCompanyNameMatch;
      });
      let totalSale = 0;
      let totalPaymentReceived = 0;
      filteredData.map((data) => {
        totalSale += data.totalBillAmount || 0;
        totalPaymentReceived += data.totalPaymentAmount || 0;
      });
      setFilteredDataValue(filteredData);
      setTotalAmount(totalSale - totalPaymentReceived);
      setFilteredDataValue(filteredData);
    }
  }

  return (
    <div id="page">
      <div className="page-content header-clear-medium">
        <div className="card card-style">
          <div className="content">
            <div className="row">
              <h4 className="col-12 mb-3 ml-1 font-18">
                You will get :
                <span style={{ color: 'red', fontWeight: 'bold' }}>
                  {' '}
                  {numberFormat(totalAmount || 0)}
                </span>{' '}
              </h4>
            </div>

            <div className="form-custom form-label form-icon mb-3">
              <i className="color-blue-dark bi bi-search"></i>
              <input
                type="text"
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
                className="form-control rounded-xs"
                id="c1"
                placeholder={`${filteredDataValue?.length || companyData?.length} companies`}
              />
            </div>
            <div className="list-group list-custom list-group-m rounded-m">
              {filteredDataValue.length > 0
                ? filteredDataValue.map((company) => (
                    <Link
                      key={company.uuid}
                      to={`/company/${company.uuid}`}
                      state={{ company }}
                      className="list-group-item d-flex">
                      <div className="pe-3 me-auto d-flex">
                        <img src={company.image || avatar} width="40" className="me-2 rounded-m" />
                        <div className="ps-1">
                          <strong className="font-600">{company.name.toUpperCase()}</strong>
                          <span className="font-600">{`${company.area.toUpperCase()}`}</span>
                        </div>
                      </div>
                      <div className="mt-n2 pb-2">
                        <div className="mx-auto" style={{ color: 'red' }}>
                          {numberFormat(
                            (company.totalBillAmount || 0) - (company.totalPaymentAmount || 0)
                          )}
                        </div>
                        <span className="p-1">{formatTime(company.updatedAt)}</span>
                      </div>
                    </Link>
                  ))
                : companyData &&
                  companyData.map((company) => (
                    <Link
                      key={company.uuid}
                      to={`/company/${company.uuid}`}
                      state={{ company }}
                      className="list-group-item d-flex">
                      <div className="pe-3 me-auto d-flex">
                        <img src={company.image || avatar} width="40" className="me-2 rounded-m" />
                        <div className="ps-1">
                          <strong className="font-600">{company.name.toUpperCase()}</strong>
                          <span className="font-800">{`${company.area.toUpperCase()}`}</span>
                        </div>
                      </div>
                      <div className="mb-n2">
                        <div className="mx-auto" style={{ color: 'red' }}>
                          {numberFormat(
                            (company.totalBillAmount || 0) - (company.totalPaymentAmount || 0)
                          )}
                        </div>
                        <span className="p-1 font-900">
                          {moment(company.updatedAt).format('MMM Do')}
                        </span>
                      </div>
                    </Link>
                  ))}
            </div>
          </div>
          {companyData?.length === 0 && (
            <div className="p-4">
              <h1>Add company from below add icon...</h1>
            </div>
          )}
          {isAdmin && (
            <div className="float" data-toast="toast-top-3">
              <Link to="/addCompany" className="btn bg-blue-light border-blue-dark">
                <i className="bi bi-building font-22"></i>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Company;
