/* eslint-disable no-unused-vars */
import { httpCall, HTTP_METHODS } from '../services/httpService';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import CommonContext from '../commonContext';
import { formatInputDate } from '../share/Utils';

/* eslint-disable react/react-in-jsx-scope */
function AddCompanyPayment() {
  const { state } = useLocation();
  const params = useParams();
  const companyPaymentData = state?.ledger;
  const companyId = params.id;
  const navigate = useNavigate();

  const { contextData, setContextData } = useContext(CommonContext);

  const { register, handleSubmit, formState, setValue, reset } = useForm({
    mode: 'onChange'
  });

  if (companyPaymentData) {
    useContext('Edit Payment', 'company_profile');
  }

  useEffect(() => {
    if (companyPaymentData) {
      setValue('payment_date', formatInputDate(companyPaymentData.date));
      // setValue('check_number', companyPaymentData.check_number);
      // setValue('credit_note', companyPaymentData.credit_note);
      // setValue('debit_note', companyPaymentData.debit_note);
      setValue('paid_amount', companyPaymentData.amount);
      setValue('notes', companyPaymentData.notes);
    }
  }, [companyPaymentData]);

  async function savePayment(data, customerId) {
    data.company_uuid = companyId;
    data.payment_date = formatInputDate(data.bill_date);
    const resp = await httpCall(HTTP_METHODS.POST, 'company-payment/add', data);
    reset();
    navigate(`/company/${companyId}`);
    statusCheck(resp, 'Added');
  }

  async function editPayment(data) {
    data.payment_date = formatInputDate(data.payment_date);
    const resp = await httpCall(
      HTTP_METHODS.POST,
      `company-payment/${companyPaymentData.uuid}`,
      data
    );
    reset();
    navigate(`/company/${companyId}`);
    statusCheck(resp, 'Edited');
  }

  async function deletePayment() {
    const resp = confirm('Are you sure?');
    if (resp) {
      await httpCall(HTTP_METHODS.DELETE, `company-payment/${companyPaymentData.uuid}`);
      statusCheck(resp, 'deleted');
      navigate(`/company/${companyId}`);
    }
  }

  const onSubmit = async (data) => {
    try {
      const customerId = location.pathname.slice(10);
      companyPaymentData ? editPayment(data) : savePayment(data, customerId);
    } catch (error) {
      setContextData({
        ...contextData,
        toast: { show: true, type: 'error', message: error.response.data.message }
      });
    }
  };

  const statusCheck = (resp, action) => {
    if (resp.status === 200) {
      setContextData({
        ...contextData,
        toast: { show: true, type: 'success', message: `payment ${action} successfully!` }
      });
    } else {
      setContextData({
        ...contextData,
        toast: { show: true, type: 'error', message: resp.message.message }
      });
    }
  };

  return (
    <div id="page">
      <div className="page-content header-clear-medium">
        <div className="content">
          <div className="row ml-2">
            <h4 className="col-9">{companyPaymentData ? 'Edit' : 'Add'} Company Payment</h4>
            <Link to={`/company/${companyId}`} className="col-3 btn color-blue-dark">
              Back
            </Link>
          </div>
          <form className="demo-animation needs-validation m-0" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-custom mb-3 form-label form-icon">
              <i className="bi bi-person-circle font-14"></i>
              <input
                type="date"
                className="form-control rounded-xs"
                id="c1"
                placeholder="Enter Payment Date"
                {...register('payment_date', { required: true })}
              />
            </div>
            <div className="form-custom mb-3 form-label form-icon">
              <i className="bi bi-shop font-14"></i>
              <input
                type="text"
                className="form-control rounded-xs"
                id="c1"
                autoComplete="off"
                placeholder="Enter amount"
                {...register('paid_amount', { required: true })}
              />
            </div>
            {/* <div className="form-custom mb-3 form-label form-icon">
              <i className="bi bi-shop font-14"></i>
              <input
                type="text"
                className="form-control rounded-xs"
                id="c1"
                placeholder="Enter Check No"
                {...register('check_number', { required: false })}
              />
            </div> */}
            {/* <div className="form-custom mb-3 form-label form-icon">
              <i className="bi bi-shop font-14"></i>
              <input
                type="text"
                className="form-control rounded-xs"
                id="c1"
                placeholder="Enter Credit Note"
                {...register('credit_note', { required: false })}
              />
            </div> */}
            {/* <div className="form-custom mb-3 form-label form-icon">
              <i className="bi bi-shop font-14"></i>
              <input
                type="text"
                className="form-control rounded-xs"
                id="c1"
                placeholder="Enter Debit Note"
                {...register('debit_note', { required: false })}
              />
            </div> */}
            <div className="form-custom mb-3 form-label form-icon">
              <i className="bi bi-card-text font-12"></i>
              <input
                type="text"
                className="form-control rounded-xs"
                id="c3"
                autoComplete="off"
                placeholder="Enter Notes"
                {...register('notes', { required: false })}
              />
            </div>
            <button
              disabled={!formState.isValid}
              className="btn btn-full bg-blue-dark rounded-xs text-uppercase font-700 w-100 btn-s mt-4"
              type="submit">
              Save
            </button>
          </form>
          {companyPaymentData && (
            <button
              onClick={deletePayment}
              className="btn btn-full bg-red-dark rounded-xs text-uppercase font-700 w-100 btn-s mt-4"
              type="submit">
              Delete
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddCompanyPayment;
