import { useContext, useEffect } from 'react';
import CommonContext from '../commonContext';

/* eslint-disable react/react-in-jsx-scope */
function SuccessToaster() {
  const { contextData, setContextData } = useContext(CommonContext);
  useEffect(() => {
    setTimeout(
      () =>
        contextData.toast &&
        setContextData({ ...contextData, toast: { show: false, type: '', message: '' } }),
      2000
    );
  }, [contextData.toast.show]);

  return (
    <>
      {contextData.toast.type === 'success' && (
        <div
          id="toast-top-3"
          className={`toast toast-bar toast-top rounded-l bg-green-dark shadow-bg shadow-bg-s fade ${
            contextData.toast.show ? 'show' : 'hide'
          }`}
          data-bs-delay="3000">
          <div className="align-self-center">
            <i className="icon icon-s bg-green-light rounded-l bi bi-check font-28 me-2"></i>
          </div>
          <div className="align-self-center ps-1">
            <strong className="font-14 mt-n1">{contextData.toast.message}</strong>
          </div>
          <div className="align-self-center ms-auto">
            <button
              onClick={() =>
                setContextData({ ...contextData, toast: { show: false, type: '', message: '' } })
              }
              type="button"
              className="btn-close btn-close-white me-2 m-auto font-9"
              data-bs-dismiss="toast"></button>
          </div>
        </div>
      )}
      {contextData.toast.type === 'error' && (
        <div
          id="toast-top-2"
          className={`toast toast-bar toast-top rounded-l bg-red-dark shadow-bg shadow-bg-s fade ${
            contextData.toast.show ? 'show' : 'hide'
          }`}>
          <div className="align-self-center">
            <i className="icon icon-s bg-white color-red-dark rounded-l shadow-s bi bi-exclamation-triangle-fill font-22 me-3"></i>
          </div>
          <div className="align-self-center">
            <strong className="font-13 mb-n2">{contextData.toast.message}</strong>
            <span className="font-10 mt-n1 opacity-70">Try again.</span>
          </div>
          <div className="align-self-center ms-auto">
            <button
              type="button"
              onClick={() =>
                setContextData({ ...contextData, toast: { show: false, type: '', message: '' } })
              }
              className="btn-close btn-close-white me-2 m-auto font-9"
              data-bs-dismiss="toast"></button>
          </div>
        </div>
      )}
    </>
  );
}

export default SuccessToaster;
