/* eslint-disable no-unused-vars */
import { httpCall, HTTP_METHODS } from '../services/httpService';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import CommonContext from '../commonContext';
import useText from '../share/hooks/useText';
import { formatInputDate } from '../share/Utils';
import { QueryClient, useQueryClient } from 'react-query';

/* eslint-disable react/react-in-jsx-scope */
function AddCompanyBill() {
  const [show, setShow] = useState(false);
  const data = JSON.parse(localStorage.getItem('places'));
  const { state } = useLocation();
  const params = useParams();
  const companyBillData = state?.ledger;
  const companyId = params.id;
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { contextData, setContextData } = useContext(CommonContext);

  const { register, handleSubmit, formState, setValue, reset } = useForm({
    mode: 'onChange'
  });

  // if (companyData) {
  //   useText(`${companyData?.name?.slice(0, 11)}(${companyData?.place.name})`, 'company_profile');
  // }

  useEffect(() => {
    if (companyBillData) {
      setValue('bill_date', formatInputDate(companyBillData.bill_date));
      setValue('trDate', formatInputDate(companyBillData.trDate));
      setValue('bill_no', companyBillData.bill_no);
      setValue('bill_amount', companyBillData.amount);
      setValue('notes', companyBillData.notes);
    }
  }, [companyBillData]);

  async function saveBill(data, customerId) {
    data.company_uuid = companyId;
    data.bill_date = formatInputDate(data.bill_date);
    data.trDate = formatInputDate(data.trDate);
    const resp = await httpCall(HTTP_METHODS.POST, 'company-bill/add', data);
    statusCheck(resp, 'Added');
  }

  async function editBill(data) {
    data.bill_date = formatInputDate(data.bill_date);
    data.trDate = formatInputDate(data.trDate);
    const resp = await httpCall(HTTP_METHODS.POST, `company-bill/${companyBillData.uuid}`, data);
    statusCheck(resp, 'Edited');
  }

  async function deleteBill() {
    const resp = confirm('Are you sure?');
    if (resp) {
      await httpCall(HTTP_METHODS.DELETE, `company-bill/${companyBillData.uuid}`);
      statusCheck(resp, 'deleted');
      navigate(`/company/${companyId}`);
    }
  }

  const onSubmit = async (data) => {
    try {
      const customerId = location.pathname.slice(10);
      companyBillData ? editBill(data) : saveBill(data, customerId);
      reset();
      navigate(`/company/${companyId}`);
    } catch (error) {
      setContextData({
        ...contextData,
        toast: { show: true, type: 'error', message: error.response.data.message }
      });
    }
  };

  const statusCheck = (resp, action) => {
    if (resp.status === 200) {
      setContextData({
        ...contextData,
        toast: { show: true, type: 'success', message: `bill ${action} successfully!` }
      });
    } else {
      setContextData({
        ...contextData,
        toast: { show: true, type: 'error', message: resp.message.message }
      });
    }
  };

  return (
    <div id="page">
      <div className="page-content header-clear-medium">
        <div className="content">
          <div className="row ml-2">
            <h4 className="col-9">{companyBillData ? 'Edit' : 'Add'} Company Bill</h4>
            <Link to={`/company/${companyId}`} className="col-3 btn color-blue-dark">
              Back
            </Link>
          </div>
          <form className="demo-animation needs-validation m-0" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-custom mb-3 form-label form-icon">
              <i className="bi bi-person-circle font-14"></i>
              <input
                type="date"
                className="form-control rounded-xs"
                id="c1"
                placeholder="Enter Bill Date"
                {...register('bill_date', { required: true })}
              />
            </div>
            <div className="form-custom mb-3 form-label form-icon">
              <i className="bi bi-shop font-14"></i>
              <input
                type="date"
                className="form-control rounded-xs"
                id="c1"
                placeholder="Enter Tr Date"
                {...register('trDate', { required: true })}
              />
            </div>
            <div className="form-custom mb-3 form-label form-icon">
              <i className="bi bi-shop font-14"></i>
              <input
                type="text"
                className="form-control rounded-xs"
                id="c1"
                autoComplete="off"
                placeholder="Enter Bill No"
                {...register('bill_no', { required: true })}
              />
            </div>
            <div className="form-custom mb-3 form-label form-icon">
              <i className="bi bi-shop font-14"></i>
              <input
                type="text"
                className="form-control rounded-xs"
                id="c1"
                autoComplete="off"
                placeholder="Enter Amount"
                {...register('bill_amount', { required: true })}
              />
            </div>
            <div className="form-custom mb-3 form-label form-icon">
              <i className="bi bi-card-text font-12"></i>
              <input
                type="text"
                className="form-control rounded-xs"
                id="c3"
                autoComplete="off"
                placeholder="Enter Notes"
                {...register('notes', { required: false })}
              />
            </div>
            <button
              disabled={!formState.isValid}
              className="btn btn-full bg-blue-dark rounded-xs text-uppercase font-700 w-100 btn-s mt-4"
              type="submit">
              Save
            </button>
            {companyBillData && (
              <button
                onClick={deleteBill}
                className="btn btn-full bg-red-dark rounded-xs text-uppercase font-700 w-100 btn-s mt-4"
                type="submit">
                Delete
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddCompanyBill;
