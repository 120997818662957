import { useContext, useEffect, useState } from 'react';
import CommonContext from '../commonContext';
import { HTTP_METHODS, httpCall } from '../services/httpService';
import { formatInputDate, numberFormat } from '../share/Utils';
// import greenIcon from '../images/green.png';
// import crossIcon from '../images/cross.jpeg';
import Moment from 'moment';
import { MONTHS } from '../share/Constant';

/* eslint-disable react/react-in-jsx-scope */
function Dashboard() {
  const { contextData, setContextData } = useContext(CommonContext);
  const [monthlyData, setMonthlyData] = useState([]);
  const [dailyData, setDailyData] = useState([]);
  const [dataByPlace, setDataByPlace] = useState([]);
  const [expenseDataByCategory, setExpenseDataByCategory] = useState([]);
  const [monthId, setMonthId] = useState(new Date().getMonth() + 1);
  const user = JSON.parse(localStorage.getItem('user'));

  const initialUrlValue = `dashboard/daily?start_date=${Moment(new Date())
    .subtract(15, 'days')
    .format('YYYY-MM-DD')}&&end_date=${formatInputDate(new Date())}`;
  const [interval, setInterval] = useState('daily');

  useEffect(() => {
    async function fetchInitialData() {
      await fetchDataDaily();
    }
    fetchInitialData();
    fetchDataByPlace();
    fetchExpenseDataByCategory(monthId);
  }, []);

  async function fetchDataDaily() {
    setInterval('daily');
    const response = await httpCall(HTTP_METHODS.GET, initialUrlValue);
    setDailyData(response.data);
    setContextData({ ...contextData, title: 'Pocketkhata' });
  }
  async function fetchDataMonthly() {
    setInterval('monthly');
    const response = await httpCall(HTTP_METHODS.GET, 'dashboard/monthly');
    setMonthlyData(response.data);
    setContextData({ ...contextData, title: 'Pocketkhata' });
  }

  async function fetchDataByPlace() {
    const response = await httpCall(HTTP_METHODS.GET, 'dashboard/area/data');
    setDataByPlace(response);
    setContextData({ ...contextData, title: 'Pocketkhata' });
    console.log('dataByPlace', response);
  }

  async function fetchExpenseDataByCategory(monthId) {
    const response = await httpCall(
      HTTP_METHODS.GET,
      `dashboard/category/monthly_expense_data/${monthId}`
    );
    setExpenseDataByCategory(response);
    setContextData({ ...contextData, title: 'Pocketkhata' });
  }

  return (
    <div id="page">
      <div className="page-content header-clear-medium">
        <div className="card card-style">
          {dailyData?.length > 1 && (
            <div className="content">
              <div className="mb-0">
                <div className="row mb-4">
                  <div className="col-6">
                    <a
                      onClick={fetchDataDaily}
                      className={`${
                        interval === 'daily'
                          ? 'bg-blue-light btn-full btn border-blue-dark'
                          : 'btn-full btn border-blue-dark color-blue-dark'
                      }`}>
                      Daily
                    </a>
                  </div>
                  <div className="col-6">
                    <a
                      onClick={fetchDataMonthly}
                      className={`${
                        interval === 'monthly'
                          ? 'bg-blue-light btn-full btn border-blue-dark'
                          : 'btn-full btn border-blue-dark color-blue-dark'
                      }`}>
                      Monthly
                    </a>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-striped color-theme mb-2">
                    <thead>
                      <tr>
                        <th className="border-fade-blue" scope="col" width={'42%'}>
                          Date
                        </th>
                        <th className="border-fade-blue" scope="col">
                          Bill
                        </th>
                        <th className="border-fade-blue" scope="col">
                          Payment
                        </th>
                        {user?.roleName === 'Admin' && (
                          <th className="border-fade-blue" scope="col">
                            Expense
                          </th>
                        )}
                      </tr>
                    </thead>
                    {interval === 'daily' && (
                      <tbody>
                        {dailyData?.length &&
                          dailyData.map((val, index) => (
                            <tr className="border-fade-blue" key={index}>
                              <td className={`${val.payment_date !== 'Total' ? '' : 'font-800'}`}>
                                {val.payment_date !== 'Total'
                                  ? Moment(val.payment_date).format('DD/MM')
                                  : 'Total'}
                              </td>
                              <td
                                className={`${
                                  val.billAmountManual &&
                                  val.totalBillAmount === val.billAmountManual
                                    ? ''
                                    : 'color-red-dark'
                                }`}>
                                {numberFormat(val.totalBillAmount)}
                              </td>
                              <td
                                className={`${
                                  val.paymentAmountManual &&
                                  val.totalPaymentAmount === val.paymentAmountManual
                                    ? ''
                                    : 'color-red-dark'
                                }`}>
                                {numberFormat(val.totalPaymentAmount)}
                              </td>
                              {user?.roleName === 'Admin' && (
                                <td
                                  className={`${
                                    val.payment_date !== 'Total' ? 'font-200' : 'font-800'
                                  }`}>
                                  {numberFormat(val.totalExpenseAmount)}
                                </td>
                              )}
                            </tr>
                          ))}
                      </tbody>
                    )}
                    {interval === 'monthly' && (
                      <tbody>
                        {monthlyData?.length &&
                          monthlyData.map((val, index) => (
                            <tr className="border-fade-blue" key={index}>
                              <td>{val.month}</td>
                              <td>{numberFormat(val.bill_amount)}</td>
                              <td>{numberFormat(val.payment_amount)}</td>
                              {user?.roleName === 'Admin' && (
                                <td>{numberFormat(val.expense_amount)}</td>
                              )}
                            </tr>
                          ))}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          )}
          {dailyData?.length == 1 && (
            <div className="p-3">
              <h2>No Data Found!</h2>
              <span>Add some bills and payments to have dashboard view!</span>
            </div>
          )}
        </div>
        {user?.roleName === 'Admin' && (
          <div className="card card-style">
            <h2 className="p-3">Place Wise Data</h2>
            {dataByPlace?.length > 1 && (
              <div className="content">
                <div className="mb-0">
                  <div className="table-responsive">
                    <table className="table table-striped color-theme mb-2">
                      <thead>
                        <tr>
                          <th className="border-fade-blue" scope="col" width={'42%'}>
                            Name
                          </th>
                          <th className="border-fade-blue" scope="col">
                            Sale
                          </th>
                          <th className="border-fade-blue" scope="col">
                            Payment
                          </th>
                          <th className="border-fade-blue" scope="col">
                            Dues
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataByPlace?.length &&
                          dataByPlace.map((val, index) => (
                            <tr className="border-fade-blue" key={index}>
                              <td>{val.placeName}</td>
                              <td>{numberFormat(val.totalBill)}</td>
                              <td>{numberFormat(val.totalPayment)}</td>
                              <td>
                                {Math.round(
                                  ((val.totalBill - val.totalPayment) / val.totalBill) * 100
                                )}
                                %
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {dataByPlace?.length == 1 && (
              <div className="p-3">
                <h2>No Data Found!</h2>
              </div>
            )}
          </div>
        )}
        {user?.roleName === 'Admin' && (
          <div className="card card-style">
            <h2 className="p-3">MONTHLY EXPENSE</h2>
            <div className="form-custom form-label form-icon p-2">
              <i className="bi bi-cash font-14"></i>
              <select
                className="form-select rounded-xs"
                id="c6"
                aria-label="Floating label select example"
                onChange={(e) => {
                  setMonthId(e.target.value);
                  fetchExpenseDataByCategory(e.target.value);
                }}>
                <option value={monthId}>
                  {MONTHS.find((month) => month.id === monthId)?.label || 'SELECT MONTH'}
                </option>
                {MONTHS?.length &&
                  MONTHS.map((month) => (
                    <option key={month.id} value={month.id}>
                      {month.label}
                    </option>
                  ))}
              </select>
            </div>
            {expenseDataByCategory?.length > 1 && (
              <div className="content">
                <div className="mb-0">
                  <div className="table-responsive">
                    <table className="table table-striped color-theme mb-2">
                      <thead>
                        <tr>
                          <th className="border-fade-blue" scope="col" width={'42%'}>
                            Category
                          </th>
                          <th className="border-fade-blue" scope="col">
                            Amount
                          </th>
                          <th className="border-fade-blue" scope="col">
                            Count
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {expenseDataByCategory?.length &&
                          expenseDataByCategory.map((val, index) => (
                            <tr className="border-fade-blue" key={index}>
                              <td>{val.typeName}</td>
                              <td>{numberFormat(val.totalAmount)}</td>
                              <td>{val.count}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {expenseDataByCategory?.length === 0 && (
              <div className="p-3">
                <h2>No Data Found!</h2>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
