/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
// import { useEffect, useState } from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import PaymentForm from '../components/modals/PaymentForm';
import { httpCall, HTTP_METHODS } from '../services/httpService';
import useText from '../share/hooks/useText';
import { formatTime, numberFormat } from '../share/Utils';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import greenIcon from '../images/green.png';
import crossIcon from '../images/cross.jpeg';
import CommonModal from '../share/CommonModal';

function Payment(props) {
  // const [paymentData, setPaymentData] = useState([]);
  const { customerPaymentData } = props;
  const [modalShow, setModalShow] = useState(false);
  const [manualPaymentModalShow, setManualPaymentModalShow] = useState(false);
  const [totalPaymentAmt, setTotalPaymentAmt] = useState({ total: '', manual: '' });
  const [paymentInputDate, setPaymentInputDate] = useState(new Date());
  const user = JSON.parse(localStorage.getItem('user'));
  const params = useParams();

  let url = `payment?payment_date=${moment(paymentInputDate).format('YYYY-MM-DD')}`;

  if (params && params.id) {
    useText('Payments', 'profile'); // update context for title and page
  } else {
    useText('Payments', 'payment'); // update context for title and page
  }

  const { data: paymentData, isLoading, error, refetch } = useQuery('payment', fetchData);

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="spinner-border color-highlight" role="status"></div>
      </div>
    );
  }

  if (error) return 'An error has occurred: ' + error.message;

  async function fetchData() {
    if (!params?.id) {
      const response = await httpCall(HTTP_METHODS.GET, url);
      setTotalPaymentAmt({
        total: response.totalAmount.total,
        manual: response.totalAmount.totalManualAmount || 0
      });
      return response.data;
    }
  }

  function getBillData(date) {
    date = new Date(date);
    setPaymentInputDate(date);
    const dateValue = moment(date).format('YYYY-MM-DD');
    url = `payment?payment_date=${dateValue}`;
    refetch();
  }

  return (
    // <div className="card overflow-visible card-style">
    //   <div className={`mb-0 ${params && params.id ? '' : 'content'}`}>
    //     <div className="d-flex mb-2">
    //       <h4 className="pe-3 me-auto">Payment Table</h4>
    //       <a
    //         onClick={() => setModalShow(true)}
    //         className="mt-n2 pb-2  btn-xs btn-full btn bg-blue-dark">
    //         Add
    //       </a>
    //     </div>
    //     {/* <p>
    //       The default table with values calculated for screen width. Icons, colors and fonts
    //       supported.
    //     </p> */}
    //     <div className="table-responsive">
    //       <table className="table color-theme mb-2">
    //         <thead>
    //           <tr>
    //             <th className="border-fade-blue" scope="col">
    //               No.
    //             </th>
    //             <th className="border-fade-blue" scope="col">
    //               Date
    //             </th>
    //             <th className="border-fade-blue" scope="col">
    //               Amount
    //             </th>
    //             <th className="border-fade-blue text-center" scope="col">
    //               Action
    //             </th>
    //           </tr>
    //         </thead>
    //         <tbody>
    //           {paymentData &&
    //             paymentData.map((payment, index) => (
    //               <tr className="border-fade-blue" key={payment.uuid}>
    //                 <td>{index + 1}</td>
    //                 <td>{new Date(payment.payment_date).toDateString()}</td>
    //                 <td>{payment.paid_amount}</td>
    //                 <td className="text-center">
    //                   <i className="bi bi-check-circle-fill color-green-dark"></i>
    //                 </td>
    //               </tr>
    //             ))}
    //         </tbody>
    //       </table>
    //     </div>
    //   </div>
    //   <PaymentForm
    //     show={modalShow}
    //     setModalShow={setModalShow}
    //     onHide={() => setModalShow(false)}
    //   />
    //   {modalShow && (
    //     <div onClick={() => setModalShow(false)} className="offcanvas-backdrop fade show"></div>
    //   )}
    // </div>
    <div id="page">
      <div className={`${params.id ? '' : 'page-content header-clear-medium'}`}>
        <div className="card card-style">
          <div className={`mb-0 ${params && params.id ? '' : 'content'}`}>
            {!params.id && (
              <div>
                <div className="form-custom form-label form-icon mb-3">
                  <i className="bi bi-calendar font-12"></i>
                  <DatePicker
                    showIcon
                    selected={paymentInputDate}
                    className="form-control rounded-xs"
                    onChange={(date) => getBillData(date)}
                    placeholderText="Enter Date in dd/mm/yyyy"
                    dateFormat="dd/MM/yyyy"
                    fixedHeight
                    onFocus={(e) => (e.target.readOnly = true)}
                    inputProps={{ readOnly: 'isMobile' }}
                    disabledKeyboardNavigation
                  />
                </div>
                {user?.roleName && (
                  <div className="card card-style p-2">
                    <div className="d-flex">
                      <h4 className="mr-1">
                        Total Amount: - {numberFormat(totalPaymentAmt.total)}
                      </h4>
                      <img
                        onClick={() => setManualPaymentModalShow(true)}
                        src={
                          totalPaymentAmt.manual === totalPaymentAmt.total ? greenIcon : crossIcon
                        }
                        width={'12%'}
                        height={'10%'}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            <div
              className="list-group list-custom list-group-m rounded-m"
              style={{ minHeight: '14rem' }}>
              {paymentData &&
                paymentData.map((payment) => (
                  <a href="tel:+1234567890" key={payment.uuid} className="list-group-item row">
                    {!params.id && (
                      <div className="col-5">
                        {/* <strong className="font-600">{index + 1}</strong> */}
                        <strong className="font-200">{payment.customer.name}</strong>
                        <span className="font-600">{`${payment.customer.place?.name}`}</span>
                      </div>
                    )}
                    <span className="col-4 mt-1 font-600">{formatTime(payment.payment_date)}</span>
                    <strong className="col-3 font-400">{numberFormat(payment.paid_amount)}</strong>
                  </a>
                ))}
              {customerPaymentData &&
                customerPaymentData.map((payment) => (
                  <a href="tel:+1234567890" key={payment.uuid} className="list-group-item row">
                    <div className="col-4">
                      {!params.id && (
                        <div className="col-5">
                          {/* <strong className="font-600">{index + 1}</strong> */}
                          <strong className="font-200">{payment.customer.name}</strong>
                          <span>{`${payment.customer.place?.name}`}</span>
                        </div>
                      )}
                    </div>
                    <span className="col-5 mt-1 font-600">{formatTime(payment.date)}</span>
                    <strong className="col-3 font-400">{numberFormat(payment.amount)}</strong>
                  </a>
                ))}
              {paymentData?.length === 0 && (
                <h4 style={{ padding: '4rem' }} className="card card-style">
                  No Data Found!
                </h4>
              )}
            </div>
          </div>
          <PaymentForm
            show={modalShow}
            setModalShow={setModalShow}
            onHide={() => setModalShow(false)}
          />
          {modalShow ||
            (manualPaymentModalShow && (
              <div
                onClick={() => setModalShow(false)}
                className="offcanvas-backdrop fade show"></div>
            ))}
          <CommonModal
            show={manualPaymentModalShow}
            page={'payment'}
            manual={totalPaymentAmt.manual}
            date={paymentInputDate}
            setShow={setManualPaymentModalShow}
            onHide={() => setManualPaymentModalShow(false)}
          />
        </div>
      </div>
    </div>
  );
}

export default Payment;
