import { useEffect, useState } from 'react';
import { HTTP_METHODS, httpCall } from '../../services/httpService';
import { formatTime } from '../../share/Utils';
import { Link } from 'react-router-dom';

/* eslint-disable react/react-in-jsx-scope */
function InvoiceList() {
  const [invoiceData, setInvoiceData] = useState([]);

  useEffect(() => {
    fetchInvoiceData();
  }, []);

  async function fetchInvoiceData() {
    const resp = await httpCall(HTTP_METHODS.GET, 'invoice');
    setInvoiceData(resp.data);
  }

  return (
    <div id="page">
      <div className="page-content header-clear-medium">
        <div className="card overflow-visible card-style">
          <div className="content mb-0">
            <div className="row">
              <h4 className="col-9">Invoices</h4>
              <div className="col-3">
                <Link
                  variant="primary"
                  className="btn bg-blue-dark border-blue-dark"
                  to={'/addInvoice'}>
                  Add Invoice
                </Link>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table color-theme mb-2">
                <thead>
                  <tr>
                    <th className="border-fade-blue" scope="col">
                      Date
                    </th>
                    <th className="border-fade-blue" scope="col">
                      Name
                    </th>
                    <th className="border-fade-blue" scope="col">
                      Amount
                    </th>
                    <th className="border-fade-blue" scope="col">
                      Status
                    </th>
                    {/* <th className="border-fade-blue text-center" scope="col">
                      Support
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {invoiceData &&
                    invoiceData.map((invoice) => (
                      <tr key={invoice.uuid} className="border-fade-blue">
                        <td>{formatTime(invoice.invoice_date)}</td>
                        <td>{invoice.customer?.name}</td>
                        <td>{invoice.total_amount}</td>
                        <td
                          className={
                            invoice.status === 'paid' ? 'color-green-dark' : 'color-red-dark'
                          }>
                          {invoice.status}
                        </td>
                        {/* <td className="text-center">
                          <i className="bi bi-check-circle-fill color-green-dark"></i>
                        </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoiceList;
