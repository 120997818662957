/* eslint-disable no-unused-vars */
import { httpCall, HTTP_METHODS } from '../services/httpService';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import CommonContext from '../commonContext';
import useText from '../share/hooks/useText';

/* eslint-disable react/react-in-jsx-scope */
function AddCompany() {
  const [show, setShow] = useState(false);
  const data = JSON.parse(localStorage.getItem('places'));
  const { state } = useLocation();
  const companyData = state?.companyData;
  const navigate = useNavigate();

  const { contextData, setContextData } = useContext(CommonContext);

  const { register, handleSubmit, formState, setValue } = useForm({
    mode: 'onChange'
  });

  // if (companyData) {
  //   useText(`${companyData?.name?.slice(0, 11)}(${companyData?.place.name})`, 'company_profile');
  // }

  useEffect(() => {
    if (companyData) {
      setValue('name', companyData.name);
      setValue('area', companyData.area);
      setValue('dueDays', companyData.dueDays);
      setValue('email', companyData.email);
      setValue('phoneNumber', companyData.phoneNumber);
    }
  }, [companyData]);

  const onSubmit = async (formData) => {
    try {
      if (companyData) {
        await httpCall(HTTP_METHODS.POST, `company/${companyData.uuid}`, formData);
        navigate(`/company/${companyData.uuid}`);
      } else {
        await httpCall(HTTP_METHODS.POST, 'company/add', formData);
        navigate('/company');
      }
      // useText({ toast: { show: true, type: 'success', message: 'Added Successfully!' } });
      setContextData({
        ...contextData,
        toast: { show: true, type: 'success', message: 'Saved Successfully!' }
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const deleteCompany = async () => {
    const resp = confirm('Are you sure?');
    if (resp) {
      await httpCall(HTTP_METHODS.DELETE, `company/${companyData.uuid}`);
      setContextData({
        ...contextData,
        toast: { show: true, type: 'success', message: 'CompanyDeleted Successfully!' }
      });
      navigate('/company');
    }
  };

  return (
    <div id="page">
      <div className="page-content header-clear-medium">
        <div className="content">
          <div className="row ml-2">
            <h4 className="col-9">{companyData ? 'Edit' : 'Add'} Company</h4>
            <Link
              to={companyData ? `/company/${companyData?.uuid}` : '/company'}
              className="col-3 btn color-blue-dark">
              Back
            </Link>
          </div>
          <form className="demo-animation needs-validation m-0" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-custom mb-3 form-label form-icon">
              <i className="bi bi-person-circle font-14"></i>
              <input
                type="text"
                className="form-control rounded-xs"
                id="c1"
                placeholder="Enter Name"
                {...register('name', { required: true })}
              />
            </div>
            <div className="form-custom mb-3 form-label form-icon">
              <i className="bi bi-shop font-14"></i>
              <input
                type="text"
                className="form-control rounded-xs"
                id="c1"
                placeholder="Enter Address"
                {...register('area', { required: true })}
              />
            </div>
            <div className="form-custom mb-3 form-label form-icon">
              <i className="bi bi-shop font-14"></i>
              <input
                type="text"
                className="form-control rounded-xs"
                id="c1"
                placeholder="Enter Due Days"
                {...register('dueDays', { required: false })}
              />
            </div>
            <div className="form-custom mb-3 form-label form-icon">
              <i className="bi bi-shop font-14"></i>
              <input
                type="text"
                className="form-control rounded-xs"
                id="c1"
                placeholder="Enter email"
                {...register('email', { required: false })}
              />
            </div>
            <div className="form-custom mb-3 form-label form-icon">
              <i className="bi bi-telephone-fill font-12"></i>
              <input
                type="tel"
                className="form-control rounded-xs"
                id="c3"
                placeholder="Enter Phone No."
                pattern="[+ 0-9]{10,15}"
                {...register('phoneNumber', { required: false })}
              />
            </div>
            <button
              disabled={!formState.isValid}
              className="btn btn-full bg-blue-dark rounded-xs text-uppercase font-700 w-100 btn-s mt-4"
              type="submit">
              Save
            </button>
            {companyData && (
              <a
                onClick={deleteCompany}
                style={{ width: '100%' }}
                className="btn btn-full gradient-red shadow-bg shadow-bg-s mt-4">
                Delete
              </a>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddCompany;
