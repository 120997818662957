/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Link, useParams } from 'react-router-dom';
import { formatTime, numberFormat } from '../share/Utils';
import { useContext, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { HTTP_METHODS, httpCall } from '../services/httpService';
import CommonContext from '../commonContext';

/* eslint-disable react/react-in-jsx-scope */
export default function CompanyProfile(props) {
  const componentRef = useRef();
  const params = useParams();
  const [netBalance, setNetBalance] = useState(0);
  const [companyData, setCompanyData] = useState({});
  const { contextData, setContextData } = useContext(CommonContext);
  const { refetch, data, isLoading, error } = useQuery('companyLedger', fetchData);

  const user = JSON.parse(localStorage.getItem('user'));
  const isAdmin = user?.roleName === 'Admin';

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="spinner-border color-highlight" role="status"></div>
      </div>
    );
  }

  if (error) return 'An error has occurred: ' + error.message;

  async function fetchData() {
    let url = `company/ledger?company_uuid=${params.id}`;
    const response = await httpCall(HTTP_METHODS.GET, url);
    setNetBalance(response.totalAmount);
    if (response?.data) {
      const billData = response.data.filter((val) => val.type === 'bill');
      setCompanyData(response.entity);
      setContextData({
        ...contextData,
        page: 'company-profile',
        title: response.entity ? `${response.entity.name?.slice(0, 20)}` : 'Companies'
      });
    }

    return response.data;
  }

  function editBillForm(event) {
    props.setBillFormValue(event);
    props.setBillModalShow(true);
  }

  function editPaymentForm(event) {
    props.setPaymentFormValue(event);
    props.setPaymentModalShow(true);
  }

  return (
    <>
      <div className="card card-style">
        <div className="mt-2" ref={componentRef}>
          <div className="p-3" style={{ margin: '54px 0 0 0' }}>
            <div className="row">
              <div className="col-9">
                <h4 className="">
                  Total Balance: -{' '}
                  <span className="color-red-light">{numberFormat(netBalance || 0)}</span>
                </h4>
              </div>
              <div className="col-3">
                <Link
                  style={{ height: 'fit-content' }}
                  to={'/addCompany'}
                  state={{ companyData }}
                  className="btn bg-orange-dark shadow-bg-m btn-xs">
                  Edit
                </Link>
              </div>
            </div>
            {/* <div style={{ margin: '20px 0 0 0' }} className="divider"></div> */}
          </div>
          <div
            className="list-group list-custom list-group-m rounded-m"
            style={{ minHeight: '14rem' }}>
            {data &&
              data.map((ledger) => (
                <Link
                  // onClick={() =>
                  //   ledger.type === 'bill'
                  //     ? isAdmin && editBillForm(ledger)
                  //     : editPaymentForm(ledger)
                  // }
                  to={
                    ledger.type === 'bill'
                      ? `/company/${params.id}/addBill`
                      : `/company/${params.id}/addPayment`
                  }
                  state={{ ledger }}
                  key={ledger.uuid}
                  className="list-group-item row"
                  // onClick={() => setModalShow(true)}
                >
                  <div className="pe-3 me-auto col-6">
                    <div className="ps-1">
                      <div>{formatTime(ledger.date)}</div>
                      {/* <span className="font-800">{formatTime(ledger.trDate)}</span> */}
                      <span className="ml-1 font-800">{ledger.notes}</span>
                    </div>
                  </div>
                  <div className="col">
                    <span className="font-800">
                      {ledger.type === 'bill' && ledger.bill_no.slice(0, 10)}
                    </span>
                    <div className="mx-auto" style={{ color: 'red' }}>
                      {ledger.type === 'bill' && numberFormat(ledger.amount)}
                    </div>
                  </div>
                  <div className="col">
                    <div className="mx-auto" style={{ color: 'green' }}>
                      {ledger.type === 'payment' && numberFormat(ledger.amount)}
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
      <div></div>
    </>
  );
}
