/* eslint-disable react/react-in-jsx-scope */
import { useParams } from 'react-router-dom';
import Moment from 'moment';

export const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  })
    .format(value)
    .slice(0, -3);

// eslint-disable-next-line react/display-name
export const withRouter = (WrappedComponent) => (props) => {
  const params = useParams();
  return (
    <WrappedComponent
      {...props}
      params={params}
      // etc...
    />
  );
};

export function formatTime(date) {
  Moment.locale('en');
  return Moment(date).format('DD-MM-YYYY');
}

export function formatInputDate(date) {
  Moment.locale('en');
  return Moment(date).format('YYYY-MM-DD');
}

export const currentDate = Moment().format('YYYY-MM-DD');
