/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CommonContext from '../../commonContext';
import { httpCall, HTTP_METHODS } from '../../services/httpService';
import { useForm } from 'react-hook-form';
import { formatInputDate } from '../../share/Utils';
import DatePicker from 'react-datepicker';

/* eslint-disable react/react-in-jsx-scope */
function BillForm(props) {
  // eslint-disable-next-line react/prop-types
  const { show, setModalShow, billFormValue } = props;
  const { contextData, setContextData } = useContext(CommonContext);
  const customerMankindFlag = contextData.customerMankindFlag;
  const [billDate, setBillDate] = useState(new Date());
  const [mankindFlag, setMankindFlag] = useState(false);
  // const [file, setFile] = useState({
  //   file: {},
  //   imageUrl: ''
  // });
  const location = useLocation();
  const initialFormState = { bill_date: '', bill_amount: '', notes: '' };
  const { register, trigger, handleSubmit, formState, setValue, reset, watch } = useForm({
    defaultValues: billFormValue ? billFormValue : initialFormState,
    mode: 'onChange'
  });
  useEffect(() => {
    if (billFormValue) {
      setValue('bill_no', billFormValue.bill_no);
      setValue('bill_amount', billFormValue.amount || billFormValue.bill_amount);
      (billFormValue.date || billFormValue.bill_date) &&
        setBillDate(new Date(billFormValue.date || billFormValue.bill_date));
      setMankindFlag(billFormValue.mankindFlag);
    }
  }, [billFormValue, show]);

  async function saveBill(data, customerId) {
    data.customer_uuid = customerId;
    const resp = await httpCall(HTTP_METHODS.POST, 'bill/add', data);
    statusCheck(resp, 'Added');
  }

  async function editBill(data) {
    const resp = await httpCall(HTTP_METHODS.POST, `bill/${billFormValue.uuid}`, data);
    statusCheck(resp, 'Edited');
  }

  async function deleteBill() {
    const resp = confirm('Are you sure?');
    if (resp) {
      await httpCall(HTTP_METHODS.DELETE, `bill/${billFormValue.uuid}`);
      setContextData({
        ...contextData,
        toast: { show: true, type: 'success', message: 'bill deleted successfully!' },
        billUpdated: true
      });
      setModalShow(false);
    }
  }

  const onSubmit = async (data) => {
    try {
      const customerId = location.pathname.slice(10);
      data.bill_date = formatInputDate(billDate);
      data.mankindFlag = mankindFlag;
      billFormValue ? editBill(data) : saveBill(data, customerId);
      setModalShow(false);
      setMankindFlag(false);
      reset();
    } catch (error) {
      setContextData({
        ...contextData,
        toast: { show: true, type: 'error', message: error.response.data.message },
        billUpdated: true
      });
    }
  };

  const statusCheck = (resp, action) => {
    if (resp.status === 200) {
      setContextData({
        ...contextData,
        toast: { show: true, type: 'success', message: `bill ${action} successfully!` },
        billUpdated: true
      });
    } else {
      setContextData({
        ...contextData,
        toast: { show: true, type: 'error', message: resp.message.message },
        billUpdated: false
      });
    }
  };

  const setCheckFlag = (e) => {
    trigger();
    console.log('Checkbox clicked. New value:', formState);
    setMankindFlag(e.target.checked);
  };

  // function handleFile(e) {
  //   setFile({
  //     file: e.target.files[0],
  //     imageUrl: URL.createObjectURL(e.target.files[0])
  //   });
  // }

  return (
    <div
      className={`offcanvas offcanvas-modal rounded-m offcanvas-detached bg-theme ${
        show ? 'show' : ''
      }`}
      style={{ width: '340px' }}
      id="menu-login">
      <form className="content" onSubmit={handleSubmit(onSubmit)}>
        {/* <h5 className="mb-n1 font-12 color-highlight font-700 text-uppercase pt-1">Welcome</h5> */}
        <div className="d-flex pb-2">
          <div className="align-self-center">
            <h5 className="mb-n1 font-12 color-highlight font-700 text-uppercase pt-1"></h5>
            <h1 className="font-700 mt-2">{billFormValue ? 'Edit Bill' : 'Add Bill'}</h1>
          </div>
          <div className="align-self-center ms-auto">
            <a
              onClick={() => {
                // setFile({});
                reset();
                setModalShow(false);
                setMankindFlag(false);
              }}
              data-bs-dismiss="offcanvas"
              className="icon icon-m">
              <i className="bi bi-x-circle-fill color-red-dark font-18 me-n4"></i>
            </a>
          </div>
        </div>
        <div className="form-custom form-label form-icon mb-3">
          <i className="bi bi-calendar font-12"></i>
          <DatePicker
            showIcon
            selected={billDate}
            className="form-control rounded-xs"
            onChange={(date) => setBillDate(date)}
            placeholderText="Enter Date in dd/mm/yyyy"
            dateFormat="dd/MM/yyyy"
            fixedHeight
            inputProps={{ readOnly: 'isMobile' }}
            onFocus={(e) => (e.target.readOnly = true)}
            disabledKeyboardNavigation
          />
        </div>
        <div className="form-custom form-label form-icon mb-3">
          <i className="bi bi-receipt font-14"></i>
          <input
            type="text"
            autoComplete="off"
            className="form-control rounded-xs"
            id="c1"
            placeholder="Bill No."
            {...register('bill_no', { required: true })}
          />
          <label htmlFor="c1" className="color-theme">
            Your Name
          </label>
        </div>
        <div className="form-custom form-label form-icon mb-3">
          <i className="bi bi-cash font-14"></i>
          <input
            type="number"
            autoComplete="off"
            className="form-control rounded-xs"
            id="c1"
            placeholder="Amount"
            {...register('bill_amount', { required: true })}
          />
        </div>
        {customerMankindFlag && (
          <div className="ml-2 d-flex pb-2" data-trigger-switch="switch-4aac">
            <div className="align-self-center">
              <h6 className="font-18">Mankind</h6>
            </div>
            <div className="ms-auto align-self-center">
              <div className="form-switch switch-blue switch-l">
                <input
                  type="checkbox"
                  onChange={setCheckFlag}
                  className="android-input"
                  style={{ width: '30px', height: '20px' }}
                  id="switch-4aac"
                  checked={mankindFlag}
                />
                <label className="custom-control-label" htmlFor="switch-4aac"></label>
              </div>
            </div>
          </div>
        )}
        <div className="form-custom form-label form-icon mb-3">
          <i className="bi bi-card-text font-14"></i>
          <input
            type="text"
            autoComplete="off"
            className="form-control rounded-xs"
            id="c1"
            placeholder="Notes"
            {...register('notes', { required: false })}
          />
          <label htmlFor="c1" className="color-theme">
            Your Name
          </label>
        </div>
        {/* <div className="file-data">
          <div className="d-flex">
            <img
              id="image-data"
              src={file.imageUrl ? file.imageUrl : emptyImg}
              width="45"
              className="img-fluid rounded-s"
              alt="img"
            />
            {file.imageUrl && (
              <a
                onClick={() => {
                  setFile({});
                }}
                className="icon icon-m"
                style={{ marginLeft: '-3.2rem' }}>
                <i className="bi bi-x-circle-fill color-red-dark font-18 me-n4"></i>
              </a>
            )}
          </div>
          <span className="upload-file-name d-block text-center">{file.file?.name}</span>
          <div>
            <input type="file" className="upload-file" onChange={handleFile} accept="image/*" />
            <p className="btn btn-full btn-m text-uppercase font-700 rounded-s upload-file-text bg-highlight">
              Upload Image
            </p>
          </div>
        </div> */}
        <button
          style={{ width: '100%' }}
          disabled={!formState.isValid}
          type="submit"
          className="btn btn-full gradient-blue shadow-bg shadow-bg-s mt-4">
          Save
        </button>
        {billFormValue && (
          <a
            onClick={() => deleteBill()}
            style={{ width: '100%' }}
            className="btn btn-full gradient-red shadow-bg shadow-bg-s mt-4">
            Delete
          </a>
        )}
      </form>
    </div>
  );
}

export default BillForm;
