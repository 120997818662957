/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import BillForm from '../components/modals/BillForm';
import { httpCall, HTTP_METHODS } from '../services/httpService';
import useText from '../share/hooks/useText';
import { formatTime, numberFormat } from '../share/Utils';
import DatePicker from 'react-datepicker';
// eslint-disable-next-line no-unused-vars
import greenIcon from '../images/green.png';
import crossIcon from '../images/cross.jpeg';
import moment from 'moment';
import CommonModal from '../share/CommonModal';

function Bill(props) {
  // eslint-disable-next-line no-unused-vars
  const { customerBillData } = props;
  // const [customerProfile, setCustomerProfile] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [manualBillModalShow, setManualBillModalShow] = useState(false);
  const [billFormValue, setBillFormValue] = useState({});
  const [totalAmt, setTotalAmt] = useState({ total: '', manual: '' });
  const [billInputDate, setBillInputDate] = useState(new Date());
  const params = useParams();
  const user = JSON.parse(localStorage.getItem('user'));

  useText('Bills', 'bill'); // update context for title and page

  let url = `bill?bill_date=${moment(billInputDate).format('YYYY-MM-DD')}`;

  if (params && params.id) {
    useText('Bills', 'profile');
  } else {
    useText('Bills', 'bill');
  }
  const { data: billData, isLoading, error, refetch } = useQuery('bill', fetchData);

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="spinner-border color-highlight" role="status"></div>
      </div>
    );
  }

  if (error) return 'An error has occurred: ' + error.message;

  async function fetchData() {
    if (!params.id) {
      const response = await httpCall(HTTP_METHODS.GET, url);
      setTotalAmt({ total: response.totalAmount, manual: response.totalManualAmount });
      return response.data;
    }
  }

  function getBillData(date) {
    date = new Date(date);
    setBillInputDate(date);
    const dateValue = moment(date).format('YYYY-MM-DD');
    url = `bill?bill_date=${dateValue}`;
    refetch();
  }

  function editBillForm(event) {
    setBillFormValue(event);
    setModalShow(true);
  }

  return (
    <div id="page">
      <div className={`${params.id ? '' : 'page-content header-clear-medium'}`}>
        <div className="card card-style">
          <div className={`mb-0 ${params && params.id ? '' : 'content'}`}>
            {!params.id && (
              <div>
                <div className="form-custom form-label form-icon mb-3">
                  <i className="bi bi-calendar font-12"></i>
                  <DatePicker
                    showIcon
                    selected={billInputDate}
                    className="form-control rounded-xs"
                    onChange={(date) => getBillData(date)}
                    placeholderText="Enter Date in dd/mm/yyyy"
                    dateFormat="dd/MM/yyyy"
                    fixedHeight
                    onFocus={(e) => (e.target.readOnly = true)}
                    inputProps={{ readOnly: 'isMobile' }}
                    disabledKeyboardNavigation
                  />
                </div>
                {user.roleName === 'Admin' && (
                  <div className="card card-style p-2">
                    <div className="d-flex">
                      <h4 className="mr-1">Total Amount: - {numberFormat(totalAmt.total)}</h4>
                      <img
                        onClick={() => setManualBillModalShow(true)}
                        src={totalAmt.manual === totalAmt.total ? greenIcon : crossIcon}
                        width={'12%'}
                        height={'10%'}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            <div
              className="list-group list-custom list-group-m rounded-m"
              style={{ minHeight: '14rem' }}>
              {customerBillData &&
                customerBillData.map((bill) => (
                  <a href="tel:+1234567890" key={bill.uuid} className="list-group-item row">
                    <div className="col-5">
                      <strong className="font-600">
                        {bill.bill_no?.slice(0, 10).replace('*EX/5/', 'R-')}
                      </strong>
                      {!params.id && <span>{bill.customer.name}</span>}
                    </div>
                    <span className="col-4 mt-1 font-600">{formatTime(bill.date)}</span>
                    <strong className="col-3 font-400">{numberFormat(bill.amount)}</strong>
                  </a>
                ))}
              {billData &&
                billData.map((bill) => (
                  <a
                    onClick={() => editBillForm(bill)}
                    key={bill.uuid}
                    className="list-group-item row">
                    <div className="col-5">
                      <strong className="font-600">
                        {bill.bill_no?.slice(0, 10).replace('*EX/5/', 'R/')}
                      </strong>
                      {!params.id && (
                        <div className="col">
                          {/* <strong className="font-600">{index + 1}</strong> */}
                          <strong className="font-200">{bill.customer.name}</strong>
                          <span className="font-600">{`${bill.customer.place?.name}`}</span>
                        </div>
                      )}
                    </div>
                    <span className="col mt-1 font-600">{formatTime(bill.bill_date)}</span>
                    <strong className="col font-400">{numberFormat(bill.bill_amount)}</strong>
                  </a>
                ))}
              {billData?.length === 0 && (
                <h4 style={{ padding: '4rem' }} className="card card-style">
                  No Data Found!
                </h4>
              )}
            </div>
          </div>
          <BillForm
            show={modalShow}
            setModalShow={setModalShow}
            billFormValue={billFormValue}
            onHide={() => setModalShow(false)}
          />
          {modalShow && (
            <div onClick={() => setModalShow(false)} className="offcanvas-backdrop fade show"></div>
          )}
          <CommonModal
            show={manualBillModalShow}
            page={'bill'}
            manual={totalAmt.manual}
            date={billInputDate}
            setShow={setManualBillModalShow}
            onHide={() => setManualBillModalShow(false)}
          />
        </div>
      </div>
    </div>
  );
}

export default Bill;
