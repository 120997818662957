/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import React, { useContext, useEffect } from 'react';
import { HTTP_METHODS, httpCall } from '../services/httpService';
import CommonContext from '../commonContext';
import { formatInputDate } from './Utils';
import { useForm } from 'react-hook-form';

function CommonModal(props) {
  // eslint-disable-next-line react/prop-types
  const { show, setShow, date, page, paymentFormValue, manual } = props;
  const { register, handleSubmit, formState, setValue } = useForm({
    mode: 'onChange'
  });
  const { contextData, setContextData } = useContext(CommonContext);

  useEffect(() => {
    if (manual) {
      setValue('total', manual);
    }
  }, [manual]);

  async function saveData(data) {
    let totalObj = {
      paymentAmountManual: +data.total
    };
    if (page === 'bill') {
      totalObj = {
        billAmountManual: +data.total
      };
    }
    totalObj.transactionDate = formatInputDate(date);
    console.log(totalObj);
    const resp = await httpCall(HTTP_METHODS.POST, 'total/add', totalObj);
    if (resp.status === 200) {
      setContextData({
        ...contextData,
        toast: {
          show: true,
          type: 'success',
          message: `Total ${page === 'bill' ? 'Bill' : 'Payment'} Added successfully!`
        }
      });
    }
    setShow(false);
  }

  return (
    <div
      className={`offcanvas offcanvas-modal rounded-m offcanvas-detached bg-theme ${
        show ? 'show' : ''
      }`}
      style={{ width: '340px' }}
      id="menu-login">
      <form className="content" onSubmit={handleSubmit(saveData)}>
        {/* <h5 className="mb-n1 font-12 color-highlight font-700 text-uppercase pt-1">Welcome</h5> */}
        <div className="d-flex pb-2">
          <div className="align-self-center">
            <h5 className="mb-n1 font-12 color-highlight font-700 text-uppercase pt-1"></h5>
            <h1 className="font-700 mt-2">
              {paymentFormValue
                ? 'Edit Payment'
                : `Add Total ${page === 'bill' ? 'Bill' : 'Payment'}`}
            </h1>
          </div>
          <div className="align-self-center ms-auto">
            <a onClick={() => setShow(false)} data-bs-dismiss="offcanvas" className="icon icon-m">
              <i className="bi bi-x-circle-fill color-red-dark font-18 me-n4"></i>
            </a>
          </div>
        </div>
        <div className="form-custom form-label form-icon mb-3">
          <i className="bi bi-card-text font-14"></i>
          <input
            type="text"
            autoComplete="off"
            className="form-control rounded-xs"
            id="c1"
            placeholder={`${page === 'bill' ? 'Bill' : 'Payment'}`}
            {...register('total', { required: true })}
          />
          <label htmlFor="c1" className="color-theme">
            Your Name
          </label>
        </div>
        <button
          style={{ width: '100%' }}
          disabled={!formState.isValid}
          type="submit"
          className="btn btn-full gradient-blue shadow-bg shadow-bg-s mt-4">
          Save
        </button>
      </form>
    </div>
  );
}

export default CommonModal;
