/* eslint-disable react/prop-types */
import React from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import EditableField from './EditableField';

function InvoiceItem(props) {
  const onItemizedItemEdit = props.onItemizedItemEdit;
  const currency = props.currency;
  const rowDel = props.onRowDel;
  const itemTable = props.items.map(function (item) {
    return (
      <ItemRow
        onItemizedItemEdit={onItemizedItemEdit}
        item={item}
        onDelEvent={rowDel}
        key={item.id}
        currency={currency}
      />
    );
  });
  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th width="60%">ITEM</th>
            <th width="10%">QTY</th>
            <th width="10%">PRICE</th>
            <th width="10%" className="text-center">
              ACTION
            </th>
          </tr>
        </thead>
        <tbody>{itemTable}</tbody>
      </Table>
      <Button className="fw-bold" onClick={props.onRowAdd}>
        Add Item
      </Button>
    </div>
  );
}

function ItemRow(props) {
  const onDelEvent = () => {
    props.onDelEvent(props.item);
  };

  return (
    <tr>
      <td style={{ width: '60%' }}>
        <EditableField
          onItemizedItemEdit={props.onItemizedItemEdit}
          cellData={{
            type: 'text',
            name: 'name',
            placeholder: 'Item name',
            value: props.item.name,
            id: props.item.id
          }}
        />
        {/* <EditableField
              onItemizedItemEdit={props.onItemizedItemEdit}
              cellData={{
                type: 'text',
                name: 'description',
                placeholder: 'Item description',
                value: props.item.description,
                id: props.item.id
              }}
            /> */}
      </td>
      <td style={{ width: '10%' }}>
        <EditableField
          onItemizedItemEdit={props.onItemizedItemEdit}
          cellData={{
            type: 'number',
            name: 'price',
            min: 1,
            textAlign: 'text-end',
            value: props.item.price,
            id: props.item.id
          }}
        />
      </td>
      <td style={{ width: '10%' }}>
        <EditableField
          onItemizedItemEdit={props.onItemizedItemEdit}
          cellData={{
            type: 'number',
            name: 'quantity',
            min: 1,
            step: '1',
            textAlign: 'text-end',
            value: props.item.quantity,
            id: props.item.id
          }}
        />
      </td>
      <td style={{ width: '10%' }}>
        <div
          className="text-center"
          style={{
            height: '33px',
            width: '33px',
            padding: '7.5px',
            marginLeft: '2vw',
            color: 'red',
            cursor: 'pointer'
          }}>
          <i onClick={onDelEvent} className="bi bi-trash-fill"></i>
        </div>
        {/* <BiTrash
              onClick={onDelEvent}
              style={{ height: '33px', width: '33px', padding: '7.5px' }}
              className="text-white mt-1 btn btn-danger"
            /> */}
      </td>
    </tr>
  );
}

export default InvoiceItem;
