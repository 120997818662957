/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { HTTP_METHODS, httpCall } from '../services/httpService';
import { formatTime, numberFormat } from '../share/Utils';
import ExpenseForm from '../components/modals/ExpenseForm';
import useText from '../share/hooks/useText';

/* eslint-disable react/react-in-jsx-scope */
export default function Expense() {
  useText('Expense', 'expense'); // update context for title and page
  const [expenseData, setExpenseData] = useState([]);
  const [expenseModalShow, setExpenseModalShow] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [expenseFormValue, setExpenseFormValue] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [filteredDataValue, setFilteredDataValue] = useState([]);

  useEffect(() => {
    fetchExpense();
  }, [expenseModalShow]);

  useEffect(() => {
    getCategory();
  }, []);

  async function getCategory() {
    const resp = await httpCall(HTTP_METHODS.GET, 'category');
    setCategoryData(resp.data);
  }

  async function fetchExpense() {
    const response = await httpCall(HTTP_METHODS.GET, 'expense');
    setExpenseData(response.data);
    setTotalAmount(response.totalAmount);
  }

  function editExpenseForm(event) {
    setExpenseFormValue(event);
    setExpenseModalShow(true);
  }

  function filterData(event) {
    const filteredData = expenseData.filter((expense) => {
      return (
        expense.type.name.toLowerCase().includes(event.toLowerCase()) ||
        expense.notes?.toLowerCase().includes(event.toLowerCase()) ||
        formatTime(expense.date)?.toLowerCase().includes(event.toLowerCase())
      );
    });
    const totalAmount = filteredData.reduce((total, current) => {
      return total + (current.amount || 0);
    }, 0);
    setTotalAmount(totalAmount);
    setFilteredDataValue(filteredData);
  }

  return (
    <div id="page">
      <div className="page-content header-clear-medium">
        <div className="card card-style">
          <div className="content">
            <h4 style={{ color: 'brown' }} className="mb-2">
              Total Expense : {numberFormat(totalAmount) || 0}
            </h4>
            <div className="form-custom form-label form-icon mb-3">
              <i className="color-blue-dark bi bi-search"></i>
              <input
                type="text"
                onChange={(e) => {
                  filterData(e.target.value);
                }}
                className="form-control rounded-xs"
                id="c1"
                placeholder={`${expenseData?.length || expenseData?.length} Expenses`}
              />
            </div>
            <div className="mt-2 list-group list-custom list-group-m rounded-m">
              {filteredDataValue.length > 0
                ? filteredDataValue.map((expense) => (
                    <a
                      onClick={() => editExpenseForm(expense)}
                      key={expense.uuid}
                      className="list-group-item row">
                      <div className="col-5">
                        <strong className="font-600">{expense.type.name}</strong>
                        <span className="font-800">{expense.notes}</span>
                      </div>
                      <span className="col mt-1 font-800">{formatTime(expense.date)}</span>
                      <strong className="col font-400">{numberFormat(expense.amount)}</strong>
                    </a>
                  ))
                : expenseData?.length &&
                  expenseData.map((expense) => (
                    <a
                      onClick={() => editExpenseForm(expense)}
                      key={expense.uuid}
                      className="list-group-item row">
                      <div className="col-5">
                        <strong className="font-600">{expense.type.name}</strong>
                        <span className="font-800">{expense.notes}</span>
                      </div>
                      <span className="col mt-1 font-800">{formatTime(expense.date)}</span>
                      <strong className="col font-400">{numberFormat(expense.amount)}</strong>
                    </a>
                  ))}
              {expenseData?.length === 0 && (
                <h4 style={{ padding: '4rem' }} className="card card-style">
                  No Data Found!
                </h4>
              )}
              <div className="float" data-toast="toast-top-3">
                <a
                  onClick={() => {
                    setExpenseModalShow(true);
                    setExpenseFormValue({});
                  }}
                  className="btn bg-blue-light border-blue-dark">
                  Add Expense
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {expenseModalShow && (
        <ExpenseForm
          show={expenseModalShow}
          expenseFormValue={expenseFormValue}
          categoryData={categoryData}
          setModalShow={setExpenseModalShow}
          onHide={() => setExpenseModalShow(false)}
        />
      )}
      {expenseModalShow && (
        <div
          onClick={() => setExpenseModalShow(false)}
          className="offcanvas-backdrop fade show"></div>
      )}
    </div>
  );
}
