/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CommonContext from '../../commonContext';
import { httpCall, HTTP_METHODS } from '../../services/httpService';
import { useForm } from 'react-hook-form';
import { formatInputDate } from '../../share/Utils';
import DatePicker from 'react-datepicker';

function PaymentForm(props) {
  // eslint-disable-next-line react/prop-types
  const { show, setModalShow, paymentFormValue } = props;
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [mankindFlag, setMankindFlag] = useState(false);
  const { contextData, setContextData } = useContext(CommonContext);
  const customerMankindFlag = contextData.customerMankindFlag;
  const location = useLocation();
  const initialFormState = {
    payment_date: contextData.selectedDate,
    paid_amount: '',
    notes: ''
  };
  const { register, trigger, handleSubmit, formState, setValue, reset } = useForm({
    defaultValues: props.paymentFormValue ? props.paymentFormValue : initialFormState,
    mode: 'onChange'
  });

  useEffect(() => {
    if (paymentFormValue) {
      setValue('paid_amount', paymentFormValue.amount);
      paymentFormValue.date && setPaymentDate(new Date(paymentFormValue.date));
      setMankindFlag(paymentFormValue.mankindFlag);
    }
  }, [paymentFormValue, show]);

  async function savePayment(data, customerId) {
    data.customer_uuid = customerId;
    data.payment_date = formatInputDate(paymentDate);
    await httpCall(HTTP_METHODS.POST, 'payment/add', data);
    setContextData({
      ...contextData,
      toast: { show: true, type: 'success', message: 'Payment Created successfully!' },
      paymentUpdated: true
    });
  }

  async function editPayment(data) {
    data.payment_date = formatInputDate(paymentDate);
    await httpCall(HTTP_METHODS.POST, `payment/${paymentFormValue.uuid}`, data);
    setContextData({
      ...contextData,
      toast: { show: true, type: 'success', message: 'Payment edited successfully!' },
      paymentUpdated: true
    });
  }

  async function deletePayment() {
    const resp = confirm('Are you sure?');
    if (resp) {
      await httpCall(HTTP_METHODS.DELETE, `payment/${paymentFormValue.uuid}`);
      setContextData({
        ...contextData,
        toast: { show: true, type: 'success', message: 'Payment deleted successfully!' },
        paymentUpdated: true
      });
      setModalShow(false);
    }
  }

  const onSubmit = async (data) => {
    try {
      const customerId = location.pathname.slice(10);
      data.mankindFlag = mankindFlag;
      paymentFormValue ? editPayment(data) : savePayment(data, customerId);
      setModalShow(false);
      setMankindFlag(false);
      reset();
    } catch (error) {
      console.log('error', error.response.data.message);
    }
  };

  function setCheckFlag(event) {
    trigger();
    setMankindFlag(event.target.checked);
  }

  return (
    <div
      className={`offcanvas offcanvas-modal rounded-m offcanvas-detached bg-theme ${
        show ? 'show' : ''
      }`}
      style={{ width: '340px' }}
      id="menu-login">
      <form className="content" onSubmit={handleSubmit(onSubmit)}>
        {/* <h5 className="mb-n1 font-12 color-highlight font-700 text-uppercase pt-1">Welcome</h5> */}
        <div className="d-flex pb-2">
          <div className="align-self-center">
            <h5 className="mb-n1 font-12 color-highlight font-700 text-uppercase pt-1"></h5>
            <h1 className="font-700 mt-2">{paymentFormValue ? 'Edit Payment' : 'Add Payment'}</h1>
          </div>
          <div className="align-self-center ms-auto">
            <a
              onClick={() => {
                setModalShow(false);
                setMankindFlag(false);
              }}
              data-bs-dismiss="offcanvas"
              className="icon icon-m">
              <i className="bi bi-x-circle-fill color-red-dark font-18 me-n4"></i>
            </a>
          </div>
        </div>
        <div className="form-custom form-label form-icon mb-3">
          <i className="bi bi-calendar font-12"></i>
          <DatePicker
            showIcon
            selected={paymentDate}
            className="form-control rounded-xs"
            onChange={(date) => setPaymentDate(date)}
            placeholderText="Enter Date in dd/mm/yyyy"
            dateFormat="dd/MM/yyyy"
            fixedHeight
            inputProps={{ readOnly: 'isMobile' }}
            onFocus={(e) => (e.target.readOnly = true)}
            disabledKeyboardNavigation
          />
          {/* <i className="bi bi-calendar font-12"></i>
          <input
            type="date"
            className="form-control rounded-xs"
            id="c5"
            min="2021-01-01"
            // defaultValue={contextData.selectedDate || ''}
            {...register('payment_date', { required: true })}
          />
          <label htmlFor="c5" className="color-theme">
            Select a Date
          </label>
          <div className="valid-feedback">HTML5 does not offer Dates Field Validation!</div> */}
        </div>
        <div className="form-custom form-label form-icon mb-3">
          <i className="bi bi-cash font-14"></i>
          <input
            type="number"
            autoComplete="off"
            className="form-control rounded-xs"
            id="c1"
            placeholder="Amount"
            {...register('paid_amount', { required: true })}
          />
        </div>
        {customerMankindFlag && (
          <div className="d-flex pb-2 mb-2" data-trigger-switch="switch-4aab">
            <div className="align-self-center">
              <h6 className="font-18">Mankind</h6>
            </div>
            <div className="ms-auto align-self-center">
              <div className="form-switch switch-blue switch-l">
                <input
                  type="checkbox"
                  onChange={setCheckFlag}
                  className="android-input"
                  style={{ width: '30px', height: '20px' }}
                  id="switch-4aac"
                  checked={mankindFlag}
                />
                <label className="custom-control-label" htmlFor="switch-4aab"></label>
              </div>
            </div>
          </div>
        )}
        <div className="form-custom form-label form-icon mb-3">
          <i className="bi bi-card-text font-14"></i>
          <input
            type="text"
            autoComplete="off"
            className="form-control rounded-xs"
            id="c1"
            placeholder="Notes"
            {...register('notes', { required: false })}
          />
          <label htmlFor="c1" className="color-theme">
            Your Name
          </label>
        </div>
        <button
          style={{ width: '100%' }}
          disabled={!formState.isValid}
          type="submit"
          className="btn btn-full gradient-blue shadow-bg shadow-bg-s mt-4">
          Save
        </button>
        {paymentFormValue && (
          <a
            onClick={() => deletePayment()}
            style={{ width: '100%' }}
            className="btn btn-full gradient-red shadow-bg shadow-bg-s mt-4">
            Delete
          </a>
        )}
      </form>
    </div>
  );
}

export default PaymentForm;
