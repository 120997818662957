/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react';
import Menu from './Menu';
import Header from './Header';
import Footer from './Footer';
// import CommonContext from '../commonContext';

function Layout(props) {
  const [show, setShow] = useState(false);
  // const { contextData } = useContext(CommonContext);

  const { setTheme } = props;

  function addShowClass() {
    setShow((prevState) => {
      return !prevState;
    });
  }

  function setThemeData() {
    setTheme((prevState) => {
      return !prevState;
    });
  }

  return (
    <div>
      <Header setThemeData={setThemeData} addShowClass={addShowClass} />

      <Footer addShowClass={addShowClass} />

      <div
        id="menu-main"
        data-menu-active="nav-comps"
        data-menu-load="menu-main.html"
        style={{ width: '280px' }}
        className={`offcanvas offcanvas-start offcanvas-detached rounded-m ${show ? 'show' : ''}`}>
        <Menu setShow={setShow} />
      </div>
      {show && <div onClick={() => setShow(false)} className="offcanvas-backdrop fade show"></div>}
    </div>
  );
}

export default Layout;
