/* eslint-disable no-unused-vars */
import { httpCall, HTTP_METHODS } from '../services/httpService';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import CommonContext from '../commonContext';
import useText from '../share/hooks/useText';

/* eslint-disable react/react-in-jsx-scope */
function AddCustomer() {
  const [show, setShow] = useState(false);
  const [mankindFlag, setMankindFlag] = useState(false);
  const data = JSON.parse(localStorage.getItem('places'));
  const { state } = useLocation();
  const customerData = state?.customer;
  const navigate = useNavigate();

  const { contextData, setContextData } = useContext(CommonContext);

  const { register, trigger, handleSubmit, formState, setValue } = useForm({
    mode: 'onChange'
  });

  if (customerData) {
    useText(`${customerData?.name?.slice(0, 11)}(${customerData?.place.name})`, 'profile');
  }

  useEffect(() => {
    if (customerData) {
      setValue('name', customerData.name);
      setValue('area', customerData.area);
      setValue('place', customerData.place.uuid);
      setValue('phoneNumber', customerData.phoneNumber);
      setMankindFlag(customerData.mankindFlag);
    }
  }, [customerData]);

  const onSubmit = async (formData) => {
    try {
      formData.place = {
        name: data.find((place) => place.uuid === formData.place).name,
        uuid: formData.place
      };
      formData.mankindFlag = mankindFlag;
      if (state?.page === 'invoice') {
        await httpCall(HTTP_METHODS.POST, 'customer/add', formData);
        navigate('/invoice');
      } else if (customerData) {
        await httpCall(HTTP_METHODS.POST, `customer/${customerData.uuid}`, formData);
        navigate(`/customer/${customerData.uuid}`);
      } else {
        await httpCall(HTTP_METHODS.POST, 'customer/add', formData);
        navigate('/customer');
      }
      useText({ toast: { show: true, type: 'success', message: 'Added Successfully!' } });
      setContextData({
        ...contextData,
        toast: { show: true, type: 'success', message: 'Saved Successfully!' }
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const deleteCustomer = async () => {
    const resp = confirm('Are you sure?');
    if (resp) {
      await httpCall(HTTP_METHODS.DELETE, `customer/${customerData.uuid}`);
      setContextData({
        ...contextData,
        toast: { show: true, type: 'success', message: 'CustomerDeleted Successfully!' }
      });
      navigate('/customer');
    }
  };

  const setCheckFlag = (e) => {
    trigger();
    setMankindFlag(e.target.checked);
  };

  async function fileUpload(event) {
    const formData = new FormData();
    formData.append('image', event.target.files[0]);
    const resp = await httpCall(HTTP_METHODS.POST, `customer/${customerData.uuid}/image`, formData);
    if (resp) {
      setContextData({
        ...contextData,
        toast: { show: true, type: 'success', message: 'Image Uploaded Successfully!' }
      });
    }
  }

  return (
    <div id="page">
      <div className="page-content header-clear-medium">
        <div className="content">
          <div className="row ml-2">
            <h4 className="col-9">{customerData ? 'Edit' : 'Add'} Customer</h4>
            <Link
              to={customerData ? `/customer/${customerData?.uuid}` : '/customer'}
              className="col-3 btn color-blue-dark">
              Back
            </Link>
          </div>
          <form className="demo-animation needs-validation m-0" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-custom mb-3 form-label form-icon">
              <i className="bi bi-person-circle font-14"></i>
              <input
                type="text"
                className="form-control rounded-xs"
                id="c1"
                placeholder="Enter Name"
                {...register('name', { required: true })}
              />
              <label htmlFor="c1" className="color-theme">
                Your Name
              </label>
              <div className="valid-feedback">Excellent!</div>
              <span>(required)</span>
            </div>
            <div className="form-custom mb-3 form-label form-icon">
              <i className="bi bi-shop font-14"></i>
              <input
                type="text"
                className="form-control rounded-xs"
                id="c1"
                placeholder="Enter Address"
                {...register('area', { required: true })}
              />
              <label htmlFor="c1" className="color-theme">
                Area
              </label>
              <div className="valid-feedback">Excellent!</div>
              <div className="invalid-feedback">Area is Missing or Invalid</div>
              <span>(required)</span>
            </div>
            <div className="form-custom form-label form-icon mb-3">
              <i className="bi bi-check-circle font-13"></i>
              <select
                className="form-select rounded-xs"
                id="c6"
                aria-label="Floating label select example"
                {...register('place', { required: false })}>
                {data?.length &&
                  data.map((place) => (
                    <option key={place.uuid} value={place.uuid}>
                      {place.name}
                    </option>
                  ))}
              </select>

              <label htmlFor="c1" className="color-theme form-label-active">
                Select Place
              </label>
            </div>
            <div className="ml-2 d-flex pb-2" data-trigger-switch="switch-4aac">
              <div className="align-self-center">
                <h6 className="font-18">Mankind</h6>
              </div>
              <div className="ms-auto align-self-center">
                <div className="form-switch switch-blue switch-l">
                  <input
                    type="checkbox"
                    onChange={setCheckFlag}
                    className="android-input"
                    style={{ width: '30px', height: '20px' }}
                    id="switch-4aac"
                    checked={mankindFlag}
                  />
                  <label className="custom-control-label" htmlFor="switch-4aac"></label>
                </div>
              </div>
            </div>
            <div className="form-custom mb-3 form-label form-icon">
              <i className="bi bi-telephone-fill font-12"></i>
              <input
                type="tel"
                className="form-control rounded-xs"
                id="c3"
                placeholder="Enter Phone No."
                pattern="[+ 0-9]{10,15}"
                {...register('phoneNumber', { required: false })}
              />
              <label htmlFor="c3" className="color-theme">
                Your Phone
              </label>
              <div className="valid-feedback">Phone Number looks good!</div>
              <div className="invalid-feedback">Phone Number is missing or is invalid.</div>
              <span>(required)</span>
            </div>
            <div>
              <input onChange={fileUpload} type="file" className="upload-file" accept="image/*" />
              <p className="btn btn-full btn-m text-uppercase font-700 rounded-s upload-file-text bg-green-dark">
                Upload Image
              </p>
            </div>
            <button
              disabled={!formState.isValid}
              className="btn btn-full bg-blue-dark rounded-xs text-uppercase font-700 w-100 btn-s mt-4"
              type="submit">
              Save
            </button>
            {customerData && (
              <a
                onClick={deleteCustomer}
                style={{ width: '100%' }}
                className="btn btn-full gradient-red shadow-bg shadow-bg-s mt-4">
                Delete
              </a>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddCustomer;
