import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import CommonContext from '../../commonContext';
import { httpCall, HTTP_METHODS } from '../../services/httpService';

/* eslint-disable react/react-in-jsx-scope */
function ResetPassword() {
  // eslint-disable-next-line react/prop-types
  // const { show } = props;
  const navigate = useNavigate();
  const { contextData, setContextData } = useContext(CommonContext);

  const { register, handleSubmit, formState } = useForm({
    mode: 'onChange'
  });

  const onSubmit = async (data) => {
    try {
      if (data.password !== data.confirmPassword) {
        alert('password not matching');
      } else {
        delete data.confirmPassword;
        const response = await httpCall(HTTP_METHODS.PUT, '/user/reset', data);
        if (response?.data) {
          setContextData({
            ...contextData,
            title: 'Login',
            page: 'login',
            toast: { show: true, type: 'success', message: 'Reset password Successful!' }
          });
          navigate('/login');
        }
      }
    } catch (error) {
      alert(error.response.data.message);
      console.log('error', error);
    }
  };

  return (
    <div id="page">
      <div className="page-content header-clear-medium">
        <div className="card card-style">
          <form className="content" onSubmit={handleSubmit(onSubmit)}>
            <h1 className="text-center font-800 font-30 mb-2">Reset Password</h1>
            <p className="text-center font-13 mt-n2 mb-3">Enter your Credentials</p>
            <div className="form-custom form-label form-icon mb-3">
              <i className="bi bi-person-circle font-14"></i>
              <input
                type="text"
                className="form-control rounded-xs"
                id="c1"
                placeholder="Email"
                {...register('email', { required: true })}
              />
              <label htmlFor="c1" className="color-theme">
                Your Name
              </label>
              <span>(required)</span>
            </div>
            <div className="form-custom form-label form-icon mb-3">
              <i className="bi bi-asterisk font-12"></i>
              <input
                type="password"
                className="form-control rounded-xs"
                id="c2"
                placeholder="Password"
                {...register('password', { required: true })}
              />
              <label htmlFor="c2" className="color-theme">
                Password
              </label>
              <span>(required)</span>
            </div>
            <div className="form-custom form-label form-icon mb-3">
              <i className="bi bi-asterisk font-12"></i>
              <input
                type="text"
                className="form-control rounded-xs"
                id="c2"
                placeholder="Confirm Password"
                {...register('confirmPassword', { required: true })}
              />
              <label htmlFor="c2" className="color-theme">
                Confirm Password
              </label>
              <span>(required)</span>
            </div>
            <button
              disabled={!formState.isValid}
              className="btn btn-full bg-blue-dark rounded-xs text-uppercase font-700 w-100 btn-s mt-4"
              type="submit">
              Reset Password
            </button>
            <div className="d-flex mt-2">
              {/* <div>
                <Link className="btn color-theme font-12"></Link>
              </div> */}
              <div className="ms-auto">
                <Link to={'/login'} className="btn color-theme font-12">
                  Login
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
